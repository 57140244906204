import { combineReducers } from 'redux'
import profile from './reducers/reducer_profile'
import permission from './reducers/reducer_permission'
import business from './reducers/reducer_business'
import rewards from './reducers/reducer_rewards'
import wallet from './reducers/reducer_wallet'
import history from './reducers/reducer_history'
import page_nav from './reducers/reducer_page_nav'
import refcode from './reducers/reducer_refcode'
import transfer_points from './reducers/reducer_transfer_points'
import language from './reducers/reducer_language'
import mycoupons from './reducers/reducer_mycoupons'
import login from './reducers/reducer_login'
import otp from './reducers/reducer_otp'
import condition from './reducers/reducer_condition'
import product from './reducers/reducer_product'
import point from './reducers/reducer_points_request'
import email from './reducers/reducer_email'
import totp from './reducers/reducer_totp'
import ip from './reducers/reducer_ip'
import custom_form from './reducers/reducer_custom_form'

const Reducer = combineReducers({
  page_nav, profile, permission, business, rewards,
  wallet, history, refcode, transfer_points, language, mycoupons,
  login, otp, condition, product, point, email, totp, ip, custom_form,
})

export default Reducer;