import Cookies from 'js-cookie'

const cookie_options = { expires: 5 * 365, secure: true }
if (window.location.protocol.indexOf('https') < 0)
  Object.assign(cookie_options, { secure: false, sameSite: 'lax' })

export function getCookie(key) {
  let secure_ck = Cookies.get(`${key}_secure`)

  if (!secure_ck) {
    let ck = Cookies.get(key)
    if (ck) {
      setCookie(key, ck)

      if (ck.indexOf('{') >= 0)
        return JSON.parse(ck) || {}

      return ck
    }
    else
      return ''
  }

  try {
    let value = atob(secure_ck)
    if (value.indexOf('{') >= 0)
      value = JSON.parse(value) || {}
    return value

  } catch (error) {
    if (secure_ck.indexOf('{') >= 0)
      return JSON.parse(secure_ck) || {}
    return secure_ck
  }
}

export function setCookie(key, value, option) {
  let op = Object.assign({}, cookie_options)
  let cc = value

  if (option)
    Object.assign(op, option)

  if (typeof value === 'object') {
    cc = getCookie(key) || {}
    cc = Object.assign({}, cc, value)
    cc = JSON.stringify(cc)
  }

  let b64 = btoa(cc)
  Cookies.set(`${key}_secure`, b64, op)
  Cookies.remove(key)

  return true;
}

export function removeCookie(key) {
  Cookies.remove(`${key}_secure`)
  Cookies.remove(key)
}