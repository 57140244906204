import { POINT } from "../action_const";

let initState = {
  send: [],
  send_success: null,
  edit_success: null,
  pointsRequestInfo: [],
  loadingInfo: true,
  new_reject: false,
  biz_new_reject: [],
  error_products_archive: {}
};

export default (state = initState, action) => {
  switch (action.type) {
    case POINT.SEND_REQUEST:
      return Object.assign({}, state, {
        send: action.data || [],
      });
    case POINT.SEND_SUCCESS:
      return Object.assign({}, state, {
        send_success: true,
      });
    case POINT.SEND_UNSUCCESS:
      return Object.assign({}, state, {
        send_success: false,
        error_products_archive: action?.data?.error_products_archive || {}
      });
    case POINT.EDIT_SUCCESS:
      return Object.assign({}, state, {
        edit_success: true,
      });
    case POINT.EDIT_UNSUCCESS:
      return Object.assign({}, state, {
        edit_success: false,
        error_products_archive: action?.data?.error_products_archive || {}
      });
    case POINT.SET_NULL:
      return Object.assign({}, state, {
        send_success: null,
        edit_success: null
      });
    case POINT.GET_REQUEST:
      return Object.assign({}, state, {
        pointsRequestInfo:
          action.count === 0
            ? action.data
            : [...state.pointsRequestInfo, ...action.data],
      });
    case POINT.SET_LOADING_INFO:
      return Object.assign({}, state, {
        loadingInfo: action.loading,
      });
    case POINT.NEW_REJECT:
      return Object.assign({}, state, {
        new_reject: true,
        biz_new_reject: action.data,
      });
    case POINT.REMOVE_NEW_REJECT:
      let temp = state.biz_new_reject.splice(
        state.biz_new_reject.indexOf(action.data),
        1
      );
      return Object.assign({}, state, {
        new_reject: false,
        biz_new_reject: temp,
      });
    case POINT.CLEAR_INFO:
      return Object.assign({}, state, {
        pointsRequestInfo: [],
      });
    default:
      return state;
  }
};
