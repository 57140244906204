import axios from 'axios'
import { getCookie } from '../libs/cookie'

export default function (options) {
  let lang = getCookie('__lang') || 'th'
  options.headers = options.headers || {}
  Object.assign(options.headers, {
    'x-language': lang
  })
  return axios(options)
} 