import { REWARDS } from "../action_const";

let initState = {
  isLoading: true,
  isLoadingRewards: false,
  isLoadingGiveaway: false,
  isLoadMore: false,
  isInitailize: true,
  coupon: [],
  reward: [],
  giveawayCoupon: [],
  isActivated: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case REWARDS.SET_REWARDS_LIST:
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingRewards: false,
        isInitailize: false,
        reward: [...action.data]
      })
    case REWARDS.SET_REWARDS_PERSISTED_STATE:
      return Object.assign({}, state, {
        isLoading: false,
        isInitailize: false,
        reward: [...action.data]
      })
    case REWARDS.SET_REWARDS_COUPON_LIST:
      return Object.assign({}, state, {
        isLoading: false,
        isInitailize: false,
        coupon: [...action.data]
      })
    case REWARDS.IS_LOADING:
      return Object.assign({}, state, {
        isLoading: state.reward.length === 0 ? true : false,
        isLoadingRewards: true
      })
    case REWARDS.IS_LOADING_COUPON:
      return Object.assign({}, state, {
        isLoading: true
      })
    case REWARDS.IS_LOADING_GIVEAWAY:
      return Object.assign({}, state, {
        isLoading: state.giveawayCoupon.length === 0 ? true : false,
        isLoadingGiveaway: true
      })
    case REWARDS.CLEAR_REWARD_COUPON:
      return Object.assign({}, state, initState)
    case REWARDS.GET_GIVEAWAY_COUPON:
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingGiveaway: false,
        giveawayCoupon: action.data
      })
    case REWARDS.SET_IS_ACTIVATED:
      return Object.assign({}, state, {
        isActivated: action.data
      })
    default:
      return state
  }
}

