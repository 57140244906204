import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Navbar from './Navbar'
import SidebarToggle from './SidebarToggle'
import NavbarBottom from './NavbarBottom'

import { requestGotoNextPage, getNextPage } from '../libs/nextPage'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = { toggle: false }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    let nextPage = getNextPage()
    if (!nextPage.includes('/points/'))
      requestGotoNextPage(this.props.history)
  }

  toggle() {
    this.setState(prevState => ({ toggle: !prevState.toggle }))
  }

  render() {
    let { store_page_nav: { title, backward, toggle, hiddenProfile, hiddenLang, blockNoti, isBusiness, isBusinessArchive, themeColor }, store_language: { dictionary, language } } = this.props
    let { store_mycoupons } = this.props
    let showNavBarBottom = (isBusiness || this.props.location.pathname.includes('/points/') || this.props.location.pathname.includes('/history/')) && !isBusinessArchive
    // let headerColor = showNavBarBottom && !isBusiness && !this.props.location.pathname.includes('/history/')

    return (
      <SidebarToggle
        toggle={this.state.toggle}
        toggleFunc={this.toggle}
        useMenuToggle={toggle}
        store_mycoupons={store_mycoupons}
        dictionary={dictionary}
        language={language}
      >
        {!this.props.store_page_nav.hidden && (
          <Navbar
            title={title}
            toggle={this.toggle}
            goTo={backward}
            useMenuToggle={toggle}
            hiddenProfile={hiddenProfile}
            hiddenLang={hiddenLang}
            isBusiness={isBusiness}
            blockNoti={blockNoti}
            themeColor={themeColor}
          />
        )}

        {this.props.children}

        {showNavBarBottom && <NavbarBottom />}
      </SidebarToggle>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_page_nav: state.page_nav,
    store_language: state.language,
    store_mycoupons: state.mycoupons,
  }
}


export default connect(mapStateToProps)(withRouter(Layout))

