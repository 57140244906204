import axios from "./../redux/axios_request";
import { PAPI } from "./_endpoint";
import { getCookie } from "../libs/cookie";

export const signinWithPhoneNumber = (phoneNumber, gRecaptcha) => {
  return axios({
    method: "post",
    url: `${PAPI}/oauth2/signinWithPhone`,
    data: {
      phoneNumber: phoneNumber,
      gRecaptcha: gRecaptcha,
    },
  });
};

export const signinWithLine = (line_user_token, business_code) => {
  return axios({
    method: "post",
    url: `${PAPI}/oauth2/signinWithLine`,
    data: {
      line_user_token: line_user_token,
      business_code: business_code,
    },
  });
};

export const verifyOTP = ({
  phoneNumberIFormat,
  refCode,
  otp,
  countryCode,
  token,
}) => {
  return axios({
    method: "post",
    url: `${PAPI}/oauth2/verifyOTP`,
    data: {
      phoneNumber: phoneNumberIFormat,
      refCode: refCode,
      otp: otp,
      countryCode: countryCode,
      token: token,
    },
  });
};

export const signInWithReference = (code) => {
  return axios({
    method: "post",
    url: `${PAPI}/oauth2/signInWithReference`,
    data: {
      code: code,
    },
  });
};

export const getAuthState = () => {
  return axios({
    method: "get",
    url: `${PAPI}/oauth2/getAuthState`,
    headers: {
      Authorization: `Bearer ${getCookie("__s")}`,
      Client: getCookie("__cid"),
    },
  });
};

export const refreshToken = () => {
  return axios({
    method: "post",
    url: `${PAPI}/oauth2/alive`,
    headers: {
      Authorization: `Bearer ${getCookie("__a")}`,
      Client: getCookie("__cid"),
    },
  });
};

export const signinWithEmail = (member_badge_code) => {
  return axios({
    method: "post",
    url: `${PAPI}/oauth2/signinWithEmail`,
    data: {
      member_badge_code: member_badge_code,
    },
  });
};
