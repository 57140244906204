import _ from 'lodash'
import { TRACE } from './../libs/utils'
import { getCookie, setCookie } from '../libs/cookie'
const themeColor = {
  white: {
    background_primary: '#FFFFFF',
    background_primary_2: '#FFFFFF',
    background_secondary: '#516373',
    color_primary: '#516373',
    color_secondary: '#FFFFFF',
    color_secondary_2: '#FFFFFF',
  },
  dark: {
    background_primary: '#AAAAAA',
    background_primary_2: '#333333',
    background_secondary: '#516373',
    color_primary: '#EEEEEE',
    color_secondary: '#DDDDDD',
    color_secondary_2: '#EEEEEE',
    dropshadow: '#111111'
  },
  silver: {
    background_primary: '#A4A5A6',
    background_secondary: '#40403F',
    color_primary: '#40403F',
    color_secondary: '#FFFFFF',
    color_secondary_2: '#FFFFFF',
  },
  gold: {
    background_primary: '#F2CE1B',
    background_primary_2: '#BF8211',
    background_secondary: '#EDC446',
    color_primary: '#333333',
    color_secondary: '#222222',
    color_secondary_2: '#333333',
  },
  red: {
    background_primary: '#59332A',
    background_secondary: '#8C030E',
    color_primary: '#FFFFFF',
    color_secondary: '#FFFFFF',
    color_secondary_2: '#FFFFFF',
  },
  navy: {
    background_primary: '#3A3D42',
    background_secondary: '#0F1C25',
    color_primary: '#EEEEEE',
    color_secondary: '#DDDDDD',
    color_secondary_2: '#EEEEEE',
  },
  brown: {
    background_primary: '#734822',
    background_secondary: '#402313',
    color_primary: '#FFFFFF',
    color_secondary: '#FFFFFF',
    color_secondary_2: '#FFFFFF',
  },
  orange: {
    background_primary: '#F2AF5C',
    background_secondary: '#F28705',
    color_primary: '#333333',
    color_secondary: '#222222',
    color_secondary_2: '#333333',
  },
}

let defaultThemeSelect = _.keys(themeColor).find(v => v === getCookie('__theme')) || 'white';
setCookie('__theme', defaultThemeSelect, { expires: 365 })

TRACE('defaultThemeSelect', defaultThemeSelect)
let defaultColor = themeColor[defaultThemeSelect] || defaultThemeSelect['white']

export const theme = {
  background_primary: defaultColor.background_primary,
  background_primary_2: defaultColor.background_primary_2 || defaultColor.background_primary,
  background_secondary: defaultColor.background_secondary,
  primary: defaultColor.color_primary,
  secondary: defaultColor.color_secondary,
  secondary_2: defaultColor.color_secondary_2,
  secondary_3: '#7d889a',
  input_placeholder: '#B1BCCF',

  shadow: defaultColor.dropshadow || '#dcdcdc',
  disabled: '#ced4da',
  green: '#00C851',
  green_deep: '#007E33',
  red: '#CA0216',
  orange: '#FFBB33',
  yellow: '#ffc107',
  blue: '#33B5E5',
  white: '#fafafa',
  white_2: '#F0F0F0',
  white_1: '#FFFFFF',
  white_border: '#e6e6e6',
  white_hilight: '#f0f0f0',
  dark: '#111111',
  dark_2: '#8c96a5',
  gold: '#946e04',
  blue_light: '#f0f8ff',
  gray: '#9da9b6',

  //Ready
  rp_sand: '#f9c33d',
  rp_petal: '#ff7398',
  rp_water: '#37efd9',
  rp_coal: '#FF781F',
  rp_leaf: '#5fbb46',

  font_xxl: '16px',
  font_xl: '16px',
  font_l: '16px',
  font_m: '14px',
  font_s: '13px',
  font_xs: '12px',
  font_xxs: '11px',
  font_bold: '600',

  // ecard type
  reward: '#00DA71',
  giveaway_coupon: '#FFBB33',
  giftvoucher: '#FD7E14',

  // status
  prepare: '#BF9F63',
  delivery: '#c5ac2d',
  delivered: '#e8e9ea',

  // ecard action type
  coupon: {
    reward: '#00c0d9',
    free_reward: '#00c0d9',
    discount: '#FE0041',
    discount_amount: '#FD7E14',
    points_in: '#FE9200',
    points_out: '#FE0041',
  }
}