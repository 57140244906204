import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavbarContainer, TitleNavBar, IconContainer, ActiveIcon, NavBarBottom } from "./Navbar.style";
import "./Navbar.css";
import { connect } from "react-redux";
import ContactInfo from "./contactInfo";
import PointMenu from "./PointMenu";
import RewardMenu from "./rewardMenu";

class NavbarSection extends Component {
  onClickHome() {
    let { history } = this.props;
    let pathname = window.location.pathname;
    let splitUrl = pathname.split('/')
    let business_code = splitUrl && splitUrl[2]
    if (pathname === `/points/${business_code}`) {
      let main_content = document.querySelectorAll("#Main-Content")[0]
          if (main_content) main_content.scrollTop = 0
    } else {
      history.push(`/points/${business_code}`)
    }
  }

	render = () => {
		let { history, store_business } = this.props;
    let { store_language: { dictionary } } = this.props
    let pathname = window.location.pathname;
    let splitUrl = pathname.split('/')
    let business_code = splitUrl && splitUrl[2]
    let this_business = store_business && store_business.list[business_code]
    let themeColor = (this_business && this_business.primary_color) || '#516373'
    
		return (
      <div>
        <div style={{ height: 80 }}></div>
        <NavBarBottom>
          <NavbarContainer>
            <IconContainer onClick={() => this.onClickHome()}>
              <ActiveIcon currentNav={pathname === `/points/${business_code}`} onClick={() => this.onClickHome()} themeColor={themeColor}>
                <i className="icon-home icon-press" />
              </ActiveIcon>
              <TitleNavBar themeColor={themeColor}>{dictionary.home_page}</TitleNavBar>
            </IconContainer>
            <IconContainer>
              <RewardMenu themeColor={themeColor} history={history} business={this_business} />
            </IconContainer>
            <IconContainer>
              <PointMenu themeColor={themeColor} history={history} />
            </IconContainer>
            <IconContainer>
              <ActiveIcon currentNav={pathname.indexOf(`/mycoupons`) > -1} onClick={() => history.push(`/points/${business_code}/mycoupons`)} themeColor={themeColor}>
                <i className="icon-coupon pr-1 nav-coupon" />
              </ActiveIcon>
              <TitleNavBar themeColor={themeColor}>{dictionary.mycoupons}</TitleNavBar>
            </IconContainer>
            <IconContainer>
              <ContactInfo history={history} themeColor={themeColor} />
            </IconContainer>
          </NavbarContainer>
        </NavBarBottom>
      </div>
		);
	};
}

const mapStateToProps = (state) => {
	return {
		store_profile: state.profile,
    store_language: state.language,
    store_business: state.business
	};
};

export default withRouter(connect(mapStateToProps)(NavbarSection));
