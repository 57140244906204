import { HISTORY } from "./../action_const";

let initState = {
  list: [],
  recently: [],
  page: 0,
  hasMore: false,
  isInitialize: true,
  isLoadMore: false,
  isRecentLoading: true,
  isLoading: false,
}

export default (state = initState, action) => {
  switch (action.type) {
    case HISTORY.START_SET_HISTORY_LIST:
      return Object.assign({}, state, {
        isLoadMore: true,
        isLoading: true,
      })
    case HISTORY.SET_HISTORY_LIST:
      return Object.assign({}, state, {
        isInitialize: false,
        page: action.page + 1,
        list: (action.page === 0) ? action.data.data : [...state.list, ...action.data.data],
        hasMore: action.data.hasMore,
        isLoadMore: false,
        isLoading: false,
      })
    case HISTORY.SET_HISTORY_LIST_PERSISTED_STATE:
      return Object.assign({}, state, {
        list: [...action.data],
      })
    case HISTORY.CLEAR_HISTORY_LIST:
      return Object.assign({}, state, {
        isInitialize: true,
        page: 0,
        list: [],
        hasMore: false,
        isLoadMore: false,
      })
    case HISTORY.START_GET_HISTORY_RECENTLY:
      return Object.assign({}, state, {
        isRecentLoading: true
      })
    case HISTORY.SET_HISTORY_RECENTLY:
      return Object.assign({}, state, {
        isRecentLoading: false,
        recently: action.data.data,
      })
    case HISTORY.SET_HISTORY_RECENTLY_PERSISTED_STATE:
      return Object.assign({}, state, {
        recently: action.data,
      })
    case HISTORY.CLEAR_HISTORY_RECENT_LIST:
      return Object.assign({}, state, {
        isRecentLoading: true,
        recently: []
      })
    default:
      return state
  }
}
