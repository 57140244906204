import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import Loadable from "react-loadable";
import _ from 'lodash'
import { genUrlByType } from "../../libs/genUrlByType";
import TagManager from "../../libs/TagManager"
import { ActiveIcon, TitleNavBar, ContactShow, IconContainer, ArrowDown } from "./Navbar.style"
import HOC_loading from "../../HOC/HOC_loading";
import { businessUnsubscribe, toggleNotificationBusiness } from "../../redux/actions/action_business";
import { setPageNav } from "../../redux/actions/action_page_nav";

const Loading = HOC_loading(() => <div></div>);

const ModalNotification = Loadable({
  loader: () => import("../ModalNotification"),
  loading: Loading,
});

const ModelUnsubscribe = Loadable({
  loader: () => import("../ModelSubscribe/ModelUnsubscribe"),
  loading: Loading,
});

export default function ContactInfo(props) {
  const { history } = props;
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenNotification, setIsOpenNotification] = useState(false)
  const [isLoadingNotification, setIsLoadingNotification] = useState(false)
  const [isOpenUnsubscribe, setIsOpenUnsubscribe] = useState(false)
  const [isLoadingUnsubscribe, setIsLoadingUnsubscribe] = useState(false)
  const store_business = useSelector((state) => state.business)
  const { dictionary } = useSelector((state) => state.language)
  const permission = useSelector((state) => state.permission)
  let business_code = window.location.pathname.split('/')[2]
  let business = store_business.list[business_code] || []
  
  let primaryBranch =
    (business &&
      business.business_branch) || [];
  primaryBranch = primaryBranch.filter(
    (val) => val.branch_type === "primary"
  );
  let contacts = (primaryBranch[0] && primaryBranch[0].branch_contacts) || [];
  let isSubscription = (business && business.subscribe_date);
  let listShowOnly = ["history", "notification", "hideBusiness"];
  
  let menuTemplateList = [
    {
      show: true,
      type: 'history',
      title: dictionary.usage_history,
      icon: "fas fa-history"
    },
    {
      show: null,
      type: "phone",
      title: dictionary.contact_phone,
      icon: "fa fa-phone-alt",
    },
    {
      show: null,
      type: "messenger",
      title: dictionary.contact_messenger,
      icon: "fab fa-facebook-messenger",
    },
    {
      show: null,
      type: "line",
      title: dictionary.contact_line,
      icon: "fab fa-line",
    },
    {
      show: null,
      type: "website",
      title: dictionary.contact_website,
      icon: "fas fa-globe-americas",
    },
    {
      level: 2,
      show: true,
      type: isSubscription ? "notification" : "hideBusiness",
      title: isSubscription ? dictionary.notification : dictionary.hide,
      icon: isSubscription
        ? business.block_noti_business
          ? "far fa-bell-slash"
          : "far fa-bell"
        : "fas fa-eye-slash",
    },
    {
      level: 2,
      show: isSubscription,
      type: "subscription",
      title: dictionary.unsubscribe,
      icon: "fas fa-ban",
    }
  ]

  let list = menuTemplateList
    .map((value) => {
      let contactFilters = contacts.filter(
        (filter) => filter.type === value.type
      );

      let _show = false;
      if (contactFilters.length > 0) {
        _show = !(contactFilters[0] && (contactFilters[0].hide || _.isEmpty(contactFilters[0].value)));
      }

      if (value.type === "subscription") {
        _show = permission.auth ? _show : false;
        _show = isSubscription
      }

      return Object.assign({}, value, {
        show: _show || listShowOnly.indexOf(value.type) > -1,
        value: contacts.value,
      });
    })
    .filter((filter) => filter.show);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('action') === 'unsubscribe') {
      setIsOpenNotification(true);
    }
  }, []);

  const showContact = (event) => {
    event.preventDefault();
    TagManager.event(`btn-contact_show`)
    setIsOpen(true)
    if (!isOpen)
      document.addEventListener("click", closeContact);
  }

  const closeContact = (event) => {
    setIsOpen(false)
    document.removeEventListener("click", closeContact)
  }

  const toggleModalNotification = () => {
    setIsOpenNotification(!isOpenNotification)
  }

  const toggleModalUnsubscribe = () => {
    setIsOpenUnsubscribe(!isOpenUnsubscribe)
  }

  const acceptUnsubscribe = () => {
    TagManager.event(`btn-set_unsubscribe`);

    setIsLoadingUnsubscribe(true)
    dispatch(
      businessUnsubscribe(business.business_code, () => {
        setIsLoadingUnsubscribe(false)
        setTimeout(history.push("/points"), 200);
      })
    );
  }

  const toggleNotification = () => {
    TagManager.event(`btn-set_noti_${!business.block_noti_business}`);

    setIsLoadingNotification(true);
    dispatch(
      toggleNotificationBusiness(
        business.business_code,
        !business.block_noti_business,
        (err, data) => {
          toggleModalNotification();
          dispatch(
            setPageNav({
              backward: "/points",
              blockNoti: (
                <>
                  {data.block_noti_business && (
                    <i className="far fa-bell-slash" />
                  )}
                </>
              )
            })
          );
          setTimeout(() => {
            setIsLoadingNotification(false);
            setIsOpenNotification(false);
          }, 200);
        }
      )
    );
  }

  const onClickBusinessBar = ({ name }) => {
    if (name === "notification") {
      toggleModalNotification();
    } else if (name === "history") {
      history.push(`/history/${business_code}`)
    } else if (name === "subscription") {
      if (business.subscribe_date)
        toggleModalUnsubscribe()
    } else if (name === "hideBusiness") {
      toggleModalUnsubscribe()
    } else {
      let contact = contacts.filter(({ hide, type }) => !hide && type === name);
      let URL = genUrlByType({ type: name, value: contact && contact[0].value })

      switch (name) {
        case "phone":
          window.location.href = URL;
          break;
        default:
          window.open(URL, "_blank");
      }
    }
  }

  return (
  <div>
    <div hidden={!isOpen}>
      <ContactShow className="p-0">
        <Row className="m-2 flex-grow-1">
        {list.map((value, key) => {
          let colSpan = list.length > 4 ? 'col-3' : list.length === 3 ? 'col-4' : 'col-6'
          return (
            <Col key={key} className={`d-flex justify-content-center ${colSpan} mb-2 px-0`}>
              <IconContainer
                name={value.type}
                onClick={() => onClickBusinessBar({ name: value.type })}
              >
                <ActiveIcon themeColor={props.themeColor}>
                  <i className={`${value.icon}`} />
                </ActiveIcon>
                <TitleNavBar themeColor={props.themeColor}>{value.title}</TitleNavBar>
              </IconContainer>
            </Col>
          );
        })}
        </Row>
      </ContactShow>
      <ArrowDown style={{ left: 'calc(90vw - 7px - env(safe-area-inset-right) + (env(safe-area-inset-left)/5))' }} />
    </div>
    <ActiveIcon themeColor={props.themeColor} currentNav={isOpen} onClick={showContact}>
      <i className="icon-dot-3 icon-press" />
    </ActiveIcon>
    <TitleNavBar themeColor={props.themeColor}>{dictionary.other}</TitleNavBar>
    {/* Modal Business Notification */}
    <ModalNotification
      business={business}
      dictionary={dictionary}
      toggle={() => toggleModalNotification()}
      isOpen={isOpenNotification}
      isLoading={isLoadingNotification}
      toggleNotification={() => toggleNotification()}
    />
    {/* Modal Unsubscribe */}
    <ModelUnsubscribe
      isHideConfirm={!isSubscription}
      business={business}
      dictionary={dictionary}
      isOpen={isOpenUnsubscribe}
      isLoading={isLoadingUnsubscribe}
      toggleUnsubscribe={() => toggleModalUnsubscribe()}
      acceptUnsubscribe={() => acceptUnsubscribe()}
    />
  </div>
  )
}