import styled from 'styled-components'

export const AppMain = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans Thai', 'Noto Sans', sans-serif;
  font-size: 0.9rem;
  color: ${props => props.theme.primary};
  background-image: linear-gradient(0deg, ${props => props.theme.background_primary_2}, ${props => props.theme.background_primary});
`