import styled from "styled-components";

export const DropdownContainer = styled.div`
  z-index: 1001;
`;

export const DropdownIcon = styled.div`
  top: 1px;
  cursor: pointer;
  display: inline;
  font-size: 18px;
  color: #f0f0f0;

  &:hover {
    color: #fff;
  }
`;
export const DropdownIconSpace = styled.div`
  top: 1px;
  margin: 0px 15px 0px 0px;
  cursor: pointer;
  display: inline;
  font-size: 16 px;
  color: #f0f0f0;
  &:hover {
    color: #fff;
  }
`;
export const DropdownIconLanguage = styled.div`
  top: 1px;
  margin: 0px 15px 0px 0px;
  cursor: pointer;
  display: inline;
  font-size: 18px;
  color: #f0f0f0;
  &:hover {
    color: #fff;
  }
`;
export const DropdownCard = styled.div`
  top: 53px;
  right: 0px;
  position: absolute;

  width: 85vw;
  min-width: 260px;
  max-width: 360px;

  z-index: 1001;
  overflow: hidden;

  color: #516373;

  background-color: ${(props) => props.theme.background_primary} !important;
  border-radius: 8px;
  overflow: hidden;

  box-shadow: 0px 18px 24px 0px rgba(0, 0, 0, 0.05),
    0px -10px 24px 0px rgba(0, 0, 0, 0.05);
`;
export const DropdownCardMessage = styled.div`
  position: fixed;
  top: 53px;
  right: 30px;
  min-width: 30%;
  min-heigth: 100px;
  background: #fff;
  z-index: 1001;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;
export const DropdownCardLanguge = styled.div`
  position: absolute;
  top: 43px;
  right: 0px;
  min-width: 190px;
  min-heigth: 100px;
  background: #fff;
  z-index: 1001;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

export const DropdownItem = styled.div`
  width: 100%;
  padding: 12px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
`;

export const DropdownItemName = styled.div`
  display: block;
  cursor: pointer;
  margin-left: 15px;
`;

export const DropdownItemMessage = styled.div`
  display: block;
  cursor: pointer;
  margin: 0px 0px 0px 15px;

  font-size: ${(props) => props.theme.font_m};
  @media (max-width: 990px) {
    font-size: ${(props) => props.theme.font_s};
  }
`;

export const DropdownItemIcon = styled.div`
  padding-left: 10px;
  display: inline-block;
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: url("${(props) => props.src}");
  background-size: cover;
  margin-bottom: 10px;
`;

export const AvatarBadge = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: url("${(props) => props.src}");
  background-size: cover;
  border-color: #dddddd;
  border: solid 1px;
  border-style: groove;
`;

export const BadgeIconSpace = styled.div`
  width: 21px;
  height: 21px;
  margin: 0px 15px 0px 0px;

  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50%;

  color: #fff;
  background: ${(props) => props.theme.info};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowUp = styled.div`
  display: ${(props) => (props.hidden ? "none" : "block")};
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 12px solid #fff;
  z-index: 1002;
  right: 10px;
  margin-top: 10px;
`;
