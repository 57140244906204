import "./SidebarToggle.css";
import {
  BadgeNew,
  LineHorizontal,
  SideFooterText,
  SideMenu,
  SideMenuHeader,
  SideMenuIcon,
  SideMenuItem,
  SideMenuLink,
  SideMenuLogo,
  SideMenuMain,
  LanguageTitle,
  LineVertical
} from "./SidebarToggle.style";
import React, { Component } from "react";
import { GlobalConfig } from "../../config";
import Logo from "../../assets/img/logo.svg";
import { NavLink } from "react-router-dom";
import Sidebar from "react-sidebar";
import { Title1, Title3 } from "../Font";
import _ from "lodash";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { getCookie } from "../../libs/cookie";
import { selectLang } from "../../redux/actions/action_language";
import { bindActionCreators } from "redux";

class SidebarToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shadow: false,
      style: {
        root: {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
          marginLeft: 'env(safe-area-inset-left)',
          marginRight: 'env(safe-area-inset-right)',
        },
        sidebar: {
          zIndex: 1500,
          position: "absolute",
          top: 0,
          bottom: 0,
          transition: "transform .3s ease-out",
          WebkitTransition: "-webkit-transform .3s ease-out",
          willChange: "transform",
          overflowY: "auto",
          backgroundColor: props.theme.background_primary,
        },
        content: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          WebkitOverflowScrolling: "touch",
          transition: "left .3s ease-out, right .3s ease-out",
          position: "absolute",
          overflowY: "auto",
          height: 'calc(100% - (env(safe-area-inset-top) + env(safe-area-inset-bottom)))',
        },
        overlay: {
          zIndex: 1490,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          visibility: "hidden",
          transition: "opacity .3s ease-out, visibility .3s ease-out",
          backgroundColor: "rgba(0,0,0,.3)",
        },
        dragHandle: {
          zIndex: 1,
          position: "fixed",
          top: 0,
          bottom: 0,
        },
      },
    };
  }

  selectLang = (lang) => {
    let { language } = this.props;
    if (language === lang) return;
    this.props.selectLang(lang)
  }

  render() {
    let _pathName = window.location.pathname || "/";
    let { dictionary, language } = this.props;
    let local = getCookie("__lang");
    let {
      store_mycoupons: { new_my_coupon },
    } = this.props;

    let {
      store_profile: { profile },
    } = this.props;

    let sideMenu = [
      {
        title: dictionary.loyalty_accounts,
        icon: "far fa-star",
        path: "/points",
        show: true,
      },
      {
        title: dictionary.scan_qr_code,
        icon: "fa fa-qrcode",
        path: "/qr",
        show: false,
      },
      {
        title: dictionary.mycoupons,
        icon: "icon-coupon fs-15 d-flex",
        path: "/mycoupons",
        show: true,
        new: !_.isEmpty(new_my_coupon),
      },
      {
        title: dictionary.my_cards,
        icon: "fas fa-feather-alt",
        path: "/cards",
        show: window.ps.fullOption,
      },
      {
        title: dictionary.profile,
        icon: "far fa-user",
        path: "/profile",
        show: true,
      },
    ];

    let sidemenuHeader = (
      <NavLink to={"/"} onClick={this.props.toggleFunc}>
        <SideMenuHeader className="border-bottom py-4">
          <SideMenuLogo alt="Pointspot-logo" className="img-fluid" src={Logo} />
          {local === "en" ? (
            <>
              <Title3 gray>{dictionary.loyalty_program}</Title3>
              <Title3 gray>{dictionary.loyalty_program2}</Title3>
            </>
          ) : (
            <Title1 gray>{dictionary.loyalty_program}</Title1>
          )}
        </SideMenuHeader>
      </NavLink>
    );

    let sidemenuBody = sideMenu.map((menu, index) => {
      let _thisPath = _pathName === menu.path;
      return (
        menu.show && (
          <SideMenuItem key={index}>
            <NavLink to={menu.path} onClick={this.props.toggleFunc}>
              <SideMenuLink className="border-bottom d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Title1
                    bold={_thisPath}
                    primary_2={!_thisPath}
                    className="text-center"
                    style={{ minWidth: 25 }}
                  >
                    <SideMenuIcon selected={_thisPath} className={menu.icon} />
                  </Title1>
                  <Title1
                    bold={_thisPath}
                    primary_2={!_thisPath}
                    style={{ marginLeft: "1.2rem" }}
                  >
                    {menu.title}
                  </Title1>
                </div>
                {menu.new ? (
                  <BadgeNew className="badge badge-danger badge-circle ml-1"></BadgeNew>
                ) : menu.title === dictionary.profile &&
                    profile &&
                    profile[0] &&
                    profile[0].email_verified === false &&
                    profile[0].email ? (
                      <div className="no-ver-email">
                        {dictionary.verify_email}
                      </div>
                    ) 
                    : <div />
                }
              </SideMenuLink>
            </NavLink>
          </SideMenuItem>
        )
      );
    });

    let sidemenufooter = (
      <div
        className="text-center p-2"
        style={{ bottom: "0", width: "100%", marginBottom: 'env(safe-area-inset-bottom)' }}
      >
        <a
          href={
            GlobalConfig.document.landingpage_link +
            "/?utm_source=pointspot&utm_medium=user-sidebar&utm_campaign=create-business"
          }
          target="_blank"
          rel="noopener noreferrer"
          className="p-0"
        >
          {local === "en" ? (
            <>
              <SideFooterText style={{ fontSize: "14px" }}>
                {dictionary.create_loyalty_program1}
              </SideFooterText>

              <br />
              <SideFooterText style={{ fontSize: "14px", color: '#0084F3' }}>
                {dictionary.create_loyalty_program2}
              </SideFooterText>
            </>
          ) : (
            <>
              <SideFooterText style={{ fontSize: "18px" }}>
                {dictionary.create_loyalty_program1}
              </SideFooterText>
              <br />
              <SideFooterText style={{ fontSize: "18px", color: '#0084F3' }}>
                {dictionary.create_loyalty_program2}
              </SideFooterText>
            </>
          )}
        </a>
        <div className="d-flex flex-column align-items-center">
          <LineHorizontal />
          <i className="icon-globe" style={{ fontSize: "20px" }} />
          <div
            className="d-flex"
            style={{ fontSize: "14px", marginBottom: 10 }}
          >
            <LanguageTitle
              name='th'
              bold={language === 'th'}
              onClick={() => this.selectLang('th')}
            >
              TH
            </LanguageTitle>
            <LineVertical />
            <LanguageTitle
              name='en'
              bold={language === 'en'}
              onClick={() => this.selectLang('en')}
            >
              EN
            </LanguageTitle>
          </div>
          <SideFooterText
            style={{ fontSize: "10px" }}
          >
            {" "}
            Version {GlobalConfig.Application.version}
          </SideFooterText>
        </div>
      </div>
    );

    let sidebarContent = (
      <SideMenu className="h-100 d-flex flex-column justify-content-between">
        <div>
          {sidemenuHeader}
          {sidemenuBody}
        </div>
        {sidemenufooter}
      </SideMenu>
    );

    return (
      <SideMenuMain>
        <Sidebar
          sidebar={sidebarContent}
          contentId={"Main-Content"}
          open={this.props.toggle}
          styles={this.state.style}
          shadow={this.state.shadow}
          onSetOpen={this.props.toggleFunc}
          touch={this.props.useMenuToggle}
        >
          {this.props.children}
        </Sidebar>
      </SideMenuMain>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return bindActionCreators(
		{
			selectLang,
		},
		dispatch
	);
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(SidebarToggle));
