import { PRODUCT } from "../action_const";

let initState = {
  categoryList: [],
  productList: [],
  allProductList: [],
  tempProduct: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case PRODUCT.CATEGORY_LIST:
      return Object.assign({}, state, {
        categoryList: action.data || [],
      });
    case PRODUCT.PRODUCT_LIST:
      return Object.assign({}, state, {
        productList: action.data || [],
      });
    case PRODUCT.ALL_PRODUCT_LIST:
      return Object.assign({}, state, {
        allProductList: action.data || [],
      });
    case PRODUCT.TEMP_PRODUCT:
      return Object.assign({}, state, {
        tempProduct: action.tempProduct || [],
      });
    default:
      return state;
  }
};
