import { PAGE } from "../action_const";

export const setPageNav = data => {
  return { type: PAGE.SET_PAGE_NAV, data: data };
};

export const setShowDropdown = ({ show }) => {
  return show
    ? { type: PAGE.SHOW_DROPDOWN }
    : { type: PAGE.HIDE_DROPDOWN }
} 