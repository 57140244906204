export default {
  locale: 'en',
  language_thai: 'ภาษาไทย',
  language_english: 'English',
  pointspot: 'Pointspot',
  hello: 'Hello!',
  slide_login: 'Slide to Login',
  login: 'Login',
  difference_mobile: 'Difference mobile number',
  mobile_number: 'Mobile number',
  otp_sending: 'Sending OTP...',
  otp_verify: 'Verifying OTP...',
  resend_otp: 'Send a new code via SMS',
  request_otp: 'Request OTP',
  submit_otp: 'Verify',
  enter_otp: 'Enter OTP here',
  accept_terms: 'By signing in to Pointspot you agree to all the',
  create_loyalty_program: 'Do you want to increase loyal customer? Apply Pointspot for your business.',
  create_loyalty_program1: 'Do you want to increase loyal customer?',
  create_loyalty_program2: 'Apply Pointspot for your business.',
  loyalty_program: 'Easy & Ready-to-use Loyalty Program with',
  loyalty_program2: 'Points and Coupons by Mobile Number',
  male: 'Male',
  female: 'Female',
  unspecified: 'Unspecified',
  create_pointspot_user_account: 'Create Pointspot User Account',
  accept_terms_conditions: 'Accept Terms & Conditions',
  date: 'Day',
  dates: 'Date',
  month: 'Month',
  year: 'Year',
  birthday_month: 'Birth month',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  first_name: 'Firstname',
  last_name: 'Lastname',
  logout: 'Logout',
  submit: 'Submit',
  skip: 'Skip',
  ok: 'OK',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  logout_question: 'Do you really want to logout ?',
  sms_notifications: 'Turn on notifications',
  disable_sms_notifications: 'Turn off notifications',
  successfully: 'successfully',
  saved: "Save completed",
  gender: 'Gender',
  email: 'Email',
  save: 'Save',
  pointspot_id: 'Pointspot ID',
  load_more: 'Load more...',
  home_page: 'Home',
  other: 'Other',
  rewards: 'Redeemable Rewards',
  recent_transaction: 'Recent transactions',
  transaction_detail: 'Transaction detail',
  attached_image: 'Attached image',
  usage_history: 'Usage history',
  recent_activity: 'Recent Activity',
  terms_and_policy: 'Terms, Conditions & Policy',
  terms_conditions: 'Terms & Conditions',
  no_terms_conditions: 'Terms & Conditions not published',
  conditions: 'Conditions',
  no_transaction: 'No transaction',
  no_activity: 'No activity',
  points: 'Points',
  total_points: 'Total points',
  points_cards: 'Loyalty Cards',
  loyalty_accounts: 'Loyalty Accounts',
  transfer: 'Transfer',
  pay: 'Pay',
  transfer_p2p: 'Transfer to friends',
  transfer_to_business: 'Pay with points',
  invalid_receiver: 'Receiver number is incorrect',
  can_not_transfer_to_yourself: 'You can not transfer points to yourself.',
  business_name: 'Business name',
  activity: 'Activity',
  my_points: 'My points',
  scan_qr_code: 'Scan QR code',
  profile: 'Profile',
  qr_code_support: 'QR Scanner support iOS 11 and later.',
  enter_refernce_code: 'Enter your reference code or Scan QR code on safari browser.',
  mobile_or_id: 'Mobile number or Pointspot ID',
  remark: 'Remark',
  send: 'Send',
  receiver_not_found: 'Receiver not found.',
  redeem: 'Redeem',
  redeem_question: 'Confirm the redemption',
  use_question: 'Confirm activate coupon',
  redeem_title: 'Redemption coupon code',
  redeem_code: 'Coupon code',
  coupon_code: 'Coupon code',
  coupon_code_reward: 'Coupon code reward',
  coupon_code_discount: 'Coupon code discount',
  redeem_code_reference: 'Coupon code',
  redeem_code_activated: 'Coupon code activated',
  redeemed_successfully: 'Coupon code',
  giftvoucher_code: 'Gift voucher code',
  please_show_code: 'Please show this code to admin.',
  please_swipe: 'Swipe to activate (for admin only)',
  for_admin_only: 'For admin only',
  use_later: 'Maybe later',
  next: 'Next',
  get_points: 'Get points',
  you_received: 'You\'ve received',
  transfer_successful: 'Transfer successful',
  pay_points_successful: 'Pay successful',
  close: 'Close',
  copy: 'Copy',
  copied: 'Copied',
  copy_message: 'Copy message',
  share_message_to_friend: 'Share this message to your friend',
  edit: 'Edit',
  edit_profile: 'Edit profile',
  from: 'From',
  to: 'To',
  PT: 'PT',
  mycoupon: 'My coupons',
  subscribe: 'Subscribe',
  unsubscribe: 'Unsubscribe',
  mycoupons: 'My coupons',
  status: 'Status',
  privacy_policy: 'Privacy Policy',
  business_privacy_policy: '$1\'s Privacy Policy', 
  accept: 'Accept',
  permissions: 'Permissions',
  hide: 'Hide',
  setting: 'Setting',
  confirm_unsubscribe: 'Do you want to unsubscribe from ',
  confirm_hide: 'Do you want hide from ',
  or_not: '?',
  qr_code: 'QR Code',
  my_qr_code: 'My QR Code',
  show_my_qr_code: 'My QR Code',
  business_setting: 'Business Setting',
  business_unsubscribe: 'Unsubscribed Businesses',
  business_unfollow_info: 'When you unsubscribe from a business,\nyou will not be able to:\n  - see business details.\n  - see details of various items.\n  - redeem rewards.\n  - transfer points to others.',
  follow: 'Follow',
  subscribe_business: 'Subscribe',
  refCode: 'ref',
  changeLanguage: 'เปลี่ยนภาษา',
  back: 'Back',
  send_sms: 'Send SMS',
  information: 'Information',
  business_information: 'Business information',
  copied_message_successfully: 'Copied',
  please_subscribe_points_redeem: 'Please subscribe business,\nto collect the points and redemption rewards.',
  please_subscribe_gift_voucher: 'Kindly subscribe\nin order to activate the gift voucher.',
  please_subscribe_coupon: 'Kindly subscribe\nin order to activate the coupon.',
  my_cards: 'My cards',
  activated: 'Activated',
  activate: 'Activate',
  expired: 'Expired',
  show_detail: 'Show details',
  points_expire_detail: 'Points expiry details',
  new: 'New',
  save_success: 'Save successfully',

  'auth/code-expired': 'OTP expired, please resend OTP again.',
  'auth/invalid-verification-code': 'OTP incorrect, please enter OTP again.',
  'oauth2/invalid-phone-number': 'Mobile number incorrect.',
  'oauth2/argument-phone-error': 'Please enter mobile number.',
  'oauth2/argument-otp-error': 'Please enter OTP',
  'oauth2/invalid-otp': 'Code incorrect, please enter code again.',
  'oauth2/otp-limit-exceeded': 'Request OTP is limit exceeded,\nplease try again in 1 hour.',
  'oauth2/operation-error': ', Please contact admin, System error occur.',
  'oauth2/invalid-reference': 'OTP expired, please resend OTP again.',
  'oauth2/reference-expired': 'Code expired, please try again.',
  'oauth2/otp-too-many-retry': 'OTP entered is incorrect too many retry,\nplease resend OTP again.',
  'otp/request-quota-exceed': 'Request OTP is limit exceeded,\nplease try again in 1 hour.',
  'verify-otp-limit': 'may',
  'cannotConnectServer': 'Cannot connect to server.',
  'maintenance': 'Pointspot is currently undergoing maintenance',
  'maintenanceDetail': '',
  'reload': 'Reload',
  loading: 'Loading',
  'see_more': 'See more',
  'valid_time': 'Valid',
  'promotion_coupon': 'Promotion Coupons',
  'reward_coupon': 'Redeemed Coupons',
  ecoupon_free: 'Reward',
  ecoupon_discount: 'Discount',
  ecoupon_givePoint: 'Give Points',
  ecoupon_redeem: 'Redeem',
  ecoupon_remain: 'Remaining',
  ecoupon_coupons: 'Coupons',
  'redeemed_valid': 'Valid',
  coupon_redeemed_valid: 'Valid',
  show_more: 'Show More',
  show_less: 'Show Less',
  business_notfound: 'Business Not Found',
  page_notfound: 'Page not found',
  please_contact: 'Please contact',
  'operation-error': 'Please contact admin, System error occur.',
  coupon: 'Coupon',
  coupon_expired: 'Coupon expired',
  coupon_activated: 'Coupon activate',
  coupon_detail: 'Coupon detail',
  coupon_cancel: 'Coupon cancelled',
  about_pointspot: 'About Pointspot',
  edit_profile_success: 'Edit profile success',
  no_coupons: 'No Coupons',
  no_rewards: 'No Rewards',
  continue_business: 'Continue',
  see_points_reward: 'See points and rewards',
  satisfaction_review: 'Satisfaction and review',
  please_rate_satisfaction: 'Please rate your satisfaction \nand review',
  thank_you_rating: 'Thank you for rating satisfaction.',
  thank_you_rating_and_review: 'Thank you for rating satisfaction\nand review',
  logging_to: 'Logging to the business',
  send_review: 'Send review',
  review_placeholder_well: 'Additional suggestions for improving service quality',
  review_placeholder: 'Please tell us what we should be improved.',
  expire_in: 'Expire in',
  expire_on: 'expire on',
  will_expire_on: ' will expire on',
  login_success: 'Login successfully',
  delete: 'Delete',
  choose: 'Choose',
  verify_email: 'Verify email',
  recaptcha_verify_invalid: 'Cannot login, please try again.',
  

  /**
   * Main business list
   */
  business_subscribe: 'Subscribed businesses',
  business_recommended: 'Businesses waiting to subscribe',

  // business header
  contact_phone: 'Phone',
  contact_facebook: 'Facebook',
  contact_messenger: 'Messenger',
  contact_line: 'LINE',
  contact_website: 'Website',
  more: 'More',

  notification: 'Notification',
  please_check_information: 'Please check the correct date of birth\nas a result of the promotion-related birthday\n \nso you can not change your birthday again.',

  you_received_sms_notification_from: 'You received SMS notification from ',
  if_you_want_to_turnoff_notification_please_click: 'If you want to turn off notification, please click',
  turnoff_notification: 'Turn off notification',
  of_business: 'of business',
  do_you_want_to_turnon_notification: 'Do you want to turn on notification',
  do_you_want_to_turnoff_notification: 'Do you want to turn off notification',
  you_can_turn_back_on_from: 'You can turn the notification again on the business pages.',

  //order
  order_by: "Order by",
  order_date_desc: "Received Date Newest",
  order_date_asc: "Received Date Oldest",
  order_expire_desc: "Expiration Date Newest",
  order_expire_asc: "Expiration Date Oldest",

  // rewards
  points_used: 'Points used',
  redemption: 'redemption',
  redeemable_up_to: 'Redeemable up to date',
  full_receive_redemption: 'Redemption have been used.',
  full_redemption: 'Full redemption',
  redemption_balance: 'Balance',
  available_redemption: 'Available',
  limited_quantity: 'limited quantity',
  day: 'day',
  not_enough_point: 'Your points are not enough.',
  not_enough_points: 'Your points are not enough.\n Need more',
  points_required: 'Points required',
  after_redeem: 'After redemption',
  reward_expire_in: 'The reward will expire in',
  reward_not_found: 'Reward not found.',
  reward_redemption_error: 'Reward redemption error. Please try again.',
  error: 'Error occurred. Please try again.',
  pointspot_noti: 'Notifications from Pointspot',
  business_hold_text: 'The $1 accounts is unavailable.\nPlease contact the store.',

  // gamble
  gamble_description: 'Points will be increased or decreased as a result.',
  error_gamble_full: 'You has been played the wheel game.',
  gamble_close_time: 'End of time to play the game.',
  not_enough_point_gamble: 'Your points are not enough for playing games.',
  lucky_wheel: 'Wheel Game',
  lucky_draw_wheel: 'Play a wheel game,\nGet more points.',
  lucky_draw_wheel_guide: 'Guide : When you press to spin, The wheel will spin and stop randomly at one area of the wheel, The result is the location where the pointer is pointing.',
  game_earn_points: 'You got points from the wheel game',
  game_lose_points: 'You lose points from the wheel game',
  result_zero: 'You did not get points from the wheel game.\nTry again later',
  earn_points_more: 'You got more points.',
  earn_game: 'Receive points from games',
  lose_points: 'You lose points',
  game_deal: 'You did not get more points',
  spin_draw: 'Spin wheel',
  try_again_later: 'Try again later',
  congratulations: 'Congratulations',
  try_again: 'Try again next time.',

  // swipe and pincode
  activate_admin_only: 'Activate \n(For admin only)',
  activate_coupon: 'Activate',
  please_try_again: 'Please try again',
  fill_in_pincode: 'Fill in PIN CODE',
  pincode_incorrect: 'PIN CODE incorrect',
  swipe_note: 'Remark',
  coupon_activate_once: 'This coupon can only be activated once.',
  activated_repeatedly: 'The activated coupon cannot be used again.',
  coupon_count_activate: 'Coupons to be used',
  coupon_remaining: 'remaining $x coupons.',
  show_qr_code: 'Show QR Code',

  // member card
  member_card: 'Member card',
  to_privilege: 'Tap a card to see privilege.',
  privilege_card: 'Privilege',
  member_card_expired: 'Member card is expired',
  member_card_cancel: 'Member card is cancelled',
  member_card_info: 'Information',
  member_card_upgrade: 'Member card upgrade',
  calculate_points_earned: 'Calculate the points earned from business over the last',
  months: 'months',

  // change mobile phone
  change_phone_number: 'Change my phone number',
  'phone-number-used': 'Phone number has already registered in Pointspot.',
  if_question_please_contact: " If you have any questions please contact",
  current_phone_number: 'Current number',
  new_phone_number: 'New number',
  confirm: 'Confirm',
  verify_otp_to_change_phone: 'Verify the OTP to change the phone number.',
  phone_number: 'Phone number',
  change_phone_number_success: 'Change Phone number successfully.',
  otp_request: 'Request OTP',
  loading_data: 'Loading data',

  // giveaway coupon
  giveaway_coupon: 'Free Privileges',
  giveaway_coupon_code: 'Giveaway coupon code',
  get_coupon: 'Get coupon',
  get_up_to: 'Get coupon up to date',
  activate_up_to: "Activate coupon up to date",
  coupon_out: 'Coupon out',
  get_coupon_full_member_limit: 'Get coupon reached the limit',
  available_get: 'Available',
  coupon_balance: 'Balance',
  get_question: 'Confirm receiving coupons',
  after_get: 'After get coupon',
  coupon_expire_in: 'The coupon will expire in',
  coupon_expire_today: "The coupon will expire today",
  coupon_not_found: 'Coupon not found.',
  get_coupon_error: 'Get coupon error. Please try again.',

  // address
  address: 'Address',
  address_name: 'Place name',
  address_line: 'Address',
  district: 'District / Area',
  sub_district: 'Sub-district / Sub-area',
  _district: 'District',
  _sub_district: 'Sub-district',
  province: 'Province',
  postal_code: 'Postal code',
  country: 'Country',
  address_placeholder: 'Number, Lane, Alley, Road',
  add_address: 'Add address',
  edit_address: 'Edit address',
  receiver_name: 'Receiver name',
  home: 'Home',
  address_name_placeholder: 'Ex. Home',
  not_found_address: 'No addresses',
  delete_address: 'Delete address',
  delete_address_confirm: 'Are you want to delete this address ?',
  delivery_address: 'Delivery address',

  // Rwards receiving
  rewards_receiving: 'Rewards receiving',
  by_self: 'Receive by self',
  by_delivery: 'Receive by delivery',
  by_self_or_delivery: 'Receive by self or delivery',
  prepare_reward: 'Preparing reward for delivery.',
  delivery_reward: 'Delivering reward',
  delivered_reward: 'Delivered successfully',
  prepare: 'Prepare',
  delivery: 'Delivery',
  delivered: 'Delivered',
  spot_redemption: 'Receive at redemption point',
  spot_redemption_receiving: 'Please show the coupon at Redemption point\nwithin 5 minutes after submit.',
  time_out: 'Time out',
  minute: 'Minute',
  not_enough_point_error: 'Not enough points',
  spot_redemption_is_active: 'There are active coupon',
  redemption_code: 'Redemption code',
  reward_redemption_coupon: 'Reward redemption coupon',
  reward_redemption_code: 'Reward redemption code',
  submit_redeem: 'Submit redemption',
  time_expire_in: 'Expire in',
  please_show_this_coupon: 'Please show this coupon',
  to_admin_to_activate: 'to admin to activate.',

  // product
  product_detail: 'Products detail',
  product_item_total: 'Products item total',
  product_deleted: 'Product is deleted',
  item: 'items',
  product_list: 'Product list',
  amount: 'Amount',
  total_amount: 'Total amount',
  sum_total_points: 'Total points',
  points_earned: 'Points earned',
  "some-product-archived": "Some products have been modified.",

  // friend get friends
  friend_get_friends: 'Friend Get Friends',
  get_friends: 'Invite',
  get_friends_title: 'Invite friends to be member',
  inviter_reward: 'Your reward',
  invitee_reward: `Friend's reward`,
  gift_voucher: 'Gift voucher',
  friend_get_friends_period: 'Friend Get Friends duration',
  invitee_subscribed_detail: 'Your friends have to subscribe this business first time.',
  invitee_active_detail: 'Your friends have to use the coupon.',
  invite_limit_detail: 'Can invite maximum $1 friends',
  message_invite_friends: 'Invitations from your friend, subscribe the $1 business first time, receives the reward immediately, click $2.',
  friend_get_friends_detail: 'Detail more',
  your_invite_code: 'Your invite code',
  invite_card: 'Invite card',
  get_friends_title_card: 'You are invited to become a member',
  scan_qr_code_to_subscribe: 'Scan the QR Code \nto subscribe this business.\nReceive rewards immediately.',
  invite_refer: 'Invited by (Phone number / Pointspot ID)',
  invite_count: 'Invited total $1/$2 person',
  invite_by_message: 'Invite message',
  invite_by_card: 'Invite card',

  // line liff
  id: 'id',
  liff_title: 'Businesses list that login with LINE LIFF app',
  confirm_remove_liff: 'Are you sure you want to disconnect from this Line business ?',
  confirm_remove_liff_title: 'Disconnect Line Business',
  remove_line_btn: 'Disconnect',
  liff_toast_success: 'Disconnect Successfully',
  liff_toast_fail: 'Disconnect Unsuccessfully',

  received_gift_voucher :'You\'ve received gift voucher',
  received_coupon :'You\'ve received coupon',

  //point request
  select_items: 'Select items',
  select_items_more: 'Select more items',
  select: 'Select',
  product_items: 'Product items',
  bath: 'THB',
  image_attachment: 'Upload receipt photo',
  select_image: 'Select photos',
  select_image_more: "Select more photos",
  send_request_successfully: "Send request successfully",
  send_request_unsuccessfully: "Send request unsuccessfully",
  product: "Product",
  collect_point_item: 'Items',
  recently_collect: 'History',
  earn: 'Earn',
  collect_point : "Collect Points",
  category: "Category",
  amount_value : "Amount",
  slip : "For example, receipt.",
  sent: "Pending approval",
  approve : "Approved",
  reject : "Rejected",
  submit_button : "Submit",
  please_enter_correct_amount : 'Numeric characters only (0-9)',
  file_types : 'Support .jpg and .png',
  max_files : 'maximum 5 files',
  max_items : 'Maximum 5 items',
  error_limit_files : 'Limit 5 files',
  oversize: 'File size is up to 30 MB',
  no_point_collected : 'No point collected',
  please_select_valid_file : 'Please select valid file',
  from_collect_points: 'Points from Collect Points',
  no_items: 'No items',
  reference: 'Reference',
  example_remark_collect_points: 'For example, campaign name or description.',
  edit_collect_points: 'Edit',
  edit_collect_points_header: 'Edit Collect Points',
  edit_date: 'Last Updated',
  remark_from_business : 'Remark from business',
  see_more_comment: 'See more',
  see_less_comment: 'See less',
  see_details: 'See details',
  collect_points_details: 'Collect Points Details',
  take_a_photo: 'Take a photo',
  take_more_photos: 'Take more photos',
  take_a_photo_again: 'Take a photo again',
  select_this_photo: 'Select this photo',
  select_camera: 'Select camera',
  opening_camera: 'Opening camera',

  //verifyEmail
  verification_sent : 'Enter the verification code we sent to',
  verify_ref: 'ref',
  verify_button: 'Verify',
  no_email : "Send a new code",
  send_email_again: 'Send a new code',
  skip_verify_email: 'Skip',
  for_verify: 'The system will send verification code to',

  'parsePhoneNumber_too_short': 'Mobile number incorrect.',
  'auth/invalid-phone-number': 'Mobile number incorrect.',
  email_already_exist: 'Email already exist',
  email_incorrect: 'Email is incorrect',
  please_enter_OTP : 'Please enter OTP',
  verify_email_successfully : 'Verify email successfully',
  benefit_email: 'To receive an email notification of points usage and monthly points summary',
  please_enter_email: 'Please enter email',
  send_otp_via_email: 'Send a code via email',
  send_otp_via_email_again: 'Send a new code via email',
  please_enter_the_email_registered_with_pointspot : 'Please enter the email registered with Pointspot',
  unable_to_login: 'Unable to login ?',
  select_account: 'Select Account',
  email_use_login:'Use in case if unable to use mobile number',

  //points
  pull: 'Pull',
  history: 'History',

  no_unsubscribed_businesses : 'No unsubscribed businesses',
  continue: 'Continue',
  required: 'Required',
  enter_information: 'Please complete the information.',
  member_info: 'Member Info',

  //setting notification
  setting_notification: 'Notification settings',
  setting_email: 'Email notification',
  points_summary: 'Monthly points summary',
  noti_of_transaction: 'Point usage notification',
  pointspot_noti_setting: 'Pointspot notification',

  //ModalSubscribe
  loyalty_platform: 'Loyalty Platform',
  allow_Pointspot: 'Allow Pointspot to send',
  allow_to_send:'your information to the business.',
  by_granting_Pointspot_permission: 'By granting Pointspot permission to share\n your name, phone number, gender,\nage, email, and address with the business,\nyou can receive targeted promotions\nand communications from the business.',
  allow: 'Allow',

  received_point_transfer: 'Recieved point transfer',

  //Line
  line_display_name: 'LINE Display Name',
  business_subscribed_line: 'Businesses subscribed through LINE',

  please_enter_information: 'Please enter information',
  save_data_successfully: 'Save data successfully',
  save_data_unsuccessfully: 'Save data unsuccessfully',

  //UserInfoForBiz
  info_shared_business: 'Information you shared with this business',
  later: 'Later',
  edit_information: 'Edit Information',

  //NoLoyaltyAccount
  you_do_not_have_loyalty_account: 'You do not have loyalty account.',
  subscribe_business_to_start_collecting_points: 'Subscribe to a business to start collecting points.',
  easy_for_customer_to_collect_point: 'Easy for customer to collect point by simply telling mobile number',
  receive_points_via_SMS: 'Receive points via SMS',
}
