import React, { Component } from 'react';
import moment from 'moment';
import { Input, Row, Col } from 'reactstrap';
import { getCookie } from './../libs/cookie';
import '../pages/Register/Register.css';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      month: '',
      year: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selected && this.props.selected) {
      let dateTime = moment(this.props.selected, 'DD-MM-YYYY')
      this.setState({
        date: format2Digit(dateTime.date()),
        month: format2Digit(dateTime.month() + 1),
        year: dateTime.year().toString()
      });
    }
  }

  componentDidMount() {
    if (this.props.selected) {
      let dateTime = moment(this.props.selected, 'DD-MM-YYYY')
      this.setState({
        date: format2Digit(dateTime.date()),
        month: format2Digit(dateTime.month() + 1),
        year: dateTime.year().toString()
      });
    }
  }

  handleChange(e) {
    let { name, value } = e.target
    let { year, month } = this.state
    let _year = name === 'year' ? value : (year || new Date().getFullYear())
    let _month = name === 'month' ? value : (month || new Date().getMonth() + 1)
    let max_date = new Date(_year, _month, 0).getDate();

    this.setState({ [name]: value, }, () => {
      let update_date = parseInt(this.state.date, 10) > max_date ? format2Digit(max_date) : this.state.date
      this.setState({ date: update_date })
      this.props.onChange(Object.assign({}, this.state, { date: update_date }))
    });
  }

  setMaxDate = () => {
    let { year, month } = this.state
    let max_date = new Date(year || new Date().getFullYear(), month || new Date().getMonth() + 1, 0).getDate();
    return max_date;
  }

  render() {
    let { disabled, dictionary, isShow } = this.props
    let size = isShow && (12 / (isShow.filter(f => ['date', 'month', 'year'].indexOf(f) > -1).length || 3))

    let current_year = new Date().getFullYear()
    let age_13 = current_year - 13
    let age_100 = current_year - 100
    let month = [dictionary.January, dictionary.February, dictionary.March, dictionary.April,
    dictionary.May, dictionary.June, dictionary.July, dictionary.August, dictionary.September,
    dictionary.October, dictionary.November, dictionary.December]
    let option_day = []
    let option_year = []

    if (isShow && isShow.indexOf('date') > -1)
      for (let i = 1; i <= this.setMaxDate(); i++) {
        i = format2Digit(i);
        option_day.push(<option key={i} value={i}>{i}</option>);
      }

    if (isShow && isShow.indexOf('year') > -1) {
      for (let i = age_13; i >= age_100; i--) {
        option_year.push(<option key={i} value={i}>{getCookie('__lang') === 'th' ? i + 543 : i} </option>);
      }
    }

    let option = [{
      id: 'date',
      name: 'date',
      data: option_day,
    }, {
      id: 'month',
      name: 'month',
      data: month.map((month, key) => <option key={key + 1} value={format2Digit(key + 1)}>{month}</option>),
    }, {
      id: 'year',
      name: 'year',
      data: option_year,
    }]

    return (
      <div>
        <Row>
          {
            option.map((val) => {
              return isShow && isShow.indexOf(val.id) > -1
                && <Col xs={size} key={val.id}>
                  <Input
                    id={val.id}
                    name={val.name}
                    type="select"
                    disabled={disabled}
                    value={this.state[val.name]}
                    onChange={this.handleChange.bind(this)}
                    className="w-100 form-control-xs d-inline-block register-form"
                    style={{ paddingLeft: '10px' }}
                    >
                    {val.id !== 'year' && <option value={''} disabled>{`-- ${dictionary[val.id]} --` || '-'}</option>}
                    {val.id !== 'month' && <option value={''} disabled>{`-- ${dictionary[val.id]} --` || '-'}</option>}
                    {val.data}
                  </Input>
                </Col>
            })
          }
        </Row>
      </div>
    );
  }
}

const format2Digit = value => ('0' + value).slice(-2)

export default DatePicker;
