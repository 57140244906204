import axios from "./../axios_request";
import { DEBUG, ERROR } from "./../../libs/utils";
import { PAPI } from "./../../libs/_endpoint";
import { BUSINESS, MEMBER } from "../action_const";
import { getCookie, removeCookie } from "../../libs/cookie";

const TAG = "action_business";

export const getBusinessList = (callback) => {
  return (dispatch) => {
    dispatch({ type: BUSINESS.BUSINESS_IS_PROCESS, data: true });
    axios({
      method: "get",
      url: `${PAPI}/az/business`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
    })
      .then((data) => {
        DEBUG(TAG, "getBusinessList");
        dispatch({ type: BUSINESS.SET_BUSINESS_LIST, data: data.data });
        if (typeof callback === "function") {
          dispatch({ type: BUSINESS.BUSINESS_IS_PROCESS, data: false });
          callback(data.data.data);
        }
       
      })
      .catch((err) => {
        return dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: {
            isError: true,
            isLoading: true,
          },
        });
      });
  };
};

export const getBusinessInfoByMember = (businessCode, callback) => {
  return (dispatch) => {
    dispatch({ type: BUSINESS.BUSINESS_IS_PROCESS, data: true });
    axios({
      method: "get",
      url: `${PAPI}/az/business/${businessCode}`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
    })
      .then((data) => {
        DEBUG(TAG, "getBusinessInfoByMember", businessCode);
        if (!data.data.result) {
          if (typeof callback === "function")
            callback(null, data.error || "result error");
          return dispatch({ type: BUSINESS.BUSINESS_IS_PROCESS, data: false });
        }
        dispatch({ type: BUSINESS.ADD_BUSINESS_LIST, data: data.data });
        if (typeof callback === "function") callback(data.data);
      })
      .catch((err) => {
        if (typeof callback === "function") callback(null, err);
        return dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: {
            isError: true,
            isLoading: true,
          },
        });
      });
  };
};

export const getBusinessInfo = (businessCode, callback, errCallback) => {
  return (dispatch) => {
    dispatch({ type: BUSINESS.BUSINESS_IS_PROCESS, data: true });
    axios({
      method: "get",
      url: `${PAPI}/business/${businessCode}`,
    })
      .then((res) => {
        DEBUG(TAG, "getBusinessInfo", businessCode);
        if (!res.data.result) {
          if (typeof errCallback === "function") errCallback();
          return dispatch({ type: BUSINESS.BUSINESS_IS_PROCESS, data: false });
        }
        if (typeof callback === "function") callback(res.data.data);
        dispatch({ type: BUSINESS.ADD_BUSINESS_LIST, data: res.data });
      })
      .catch((err) => {
        return dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: {
            isError: true,
            isLoading: true,
          },
        });
      });
  };
};

export const businessSubscribe = (
  { businessCode, reference_code, referer_type, referer_value, provider },
  callback
) => {
  return (dispatch) => {
    dispatch({ type: BUSINESS.SUBSCRIBE_BUSINESS_IS_START });
    axios({
      method: "post",
      url: `${PAPI}/az/business/${businessCode}/subscribe`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { reference_code, referer_type, referer_value, provider },
    }).then((data) => {
      DEBUG(TAG, "Subscribe", businessCode);
      dispatch({
        type: BUSINESS.SUBSCRIBE_BUSINESS,
        data: data.data,
        business: businessCode,
      });
      if (!data.data.result) {
        if (typeof callback === "function") callback(data.data.error, data.data);
      } else {
        if (typeof callback === "function") callback(null, data.data);
      }
      if (data.data.result) removeCookie(`_referer_${businessCode}`);
    });
  };
};

export const businessUnsubscribe = (businessCode, callback) => {
  return (dispatch) => {
    dispatch({ type: BUSINESS.UNSUBSCRIBE_BUSINESS_IS_START });
    axios({
      method: "post",
      url: `${PAPI}/az/business/${businessCode}/unsubscribe`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
    }).then((data) => {
      DEBUG(TAG, "Unsubscribe", businessCode);
      dispatch({
        type: BUSINESS.UNSUBSCRIBE_BUSINESS,
        data: data.data,
        business: businessCode,
      });
      if (typeof callback === "function") callback();
    });
  };
};

export const businessFollow = (callback) => {
  return () => {
    if (typeof callback === "function") callback();
  };
  /*
  return dispatch => {
    dispatch({ type: BUSINESS.FOLLOW_BUSINESS_IS_START })
    axios({
      method: 'post',
      url: `${PAPI}/az/business/${businessCode}/followBusiness`,
      headers: {
        'Authorization': `Bearer ${getCookie('__a')}`,
        'Client': getCookie('__cid')
      }
    }).then(data => {
      dispatch({
        type: BUSINESS.FOLLOW_BUSINESS,
        data: data.data,
        business: businessCode
      })
      if (typeof callback === "function") callback()
    })
  }
  */
};

export const toggleNotificationBusiness = (
  businessCode,
  block_noti_business,
  callback
) => {
  return (dispatch) => {
    axios({
      method: "put",
      url: `${PAPI}/az/business/${businessCode}/notification`,
      data: { block_noti_business: block_noti_business },
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
    })
      .then(({ data }) => {
        DEBUG(
          TAG,
          "Toggle notification",
          businessCode,
          "Block:",
          block_noti_business
        );
        if (typeof callback === "function") callback(null, data.data);
        dispatch({
          type: BUSINESS.TOGGLE_NOTIFICATION_BUSINESS,
          data: data.data,
        });
      })
      .catch((err) => {
        if (typeof callback === "function") callback(err);
        ERROR("toggleNotificationBusiness", err);
      });
  };
};

export const getBusinessMemberCard = (business_code, callback) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${PAPI}/az/business/${business_code}/memberCard`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: BUSINESS.SET_CURRENT_MEMBER_CARD,
          data: data.data,
        });
        return typeof callback === "function" && callback(null, data.data);
      })
      .catch((err) => {
        ERROR("getBusinessMemberCard", err);
        if (typeof callback === "function") callback(err);
        return dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: { isError: true },
        });
      });
  };
};

export const getAutomationMemberCardInfo = (business_code, month, callback) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/business/${business_code}/memberCard/automation`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { month },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data.data);

        } else {
          if (typeof callback === "function")
            callback(data.error || "result error");
        }
      })
      .catch((err) => {
        ERROR("getAutomationMemberCardInfo catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

export const checkMemberHasCard = (business_code) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${PAPI}/az/business/${business_code}/memberCard/checkMemberHasCard`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: BUSINESS.SET_MEMBER_HAS_CARD,
          data: data.data.result,
        });
      })
      .catch((err) => {
        dispatch({ type: BUSINESS.SET_MEMBER_HAS_CARD, data: false });
      });
  };
};

export const updateChallengeCode = (business_code, challengeCode, callback) => {
  return (dispatch) => {
    axios({
      method: "put",
      url: `${PAPI}/az/business/${business_code}/challenge`,
      data: { challengeCode: challengeCode },
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data.result);
        } else {
          if (typeof callback === "function")
            callback(data.error || "result error");
        }
      })
      .catch((err) => {
        ERROR("updateChallengeCode catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

export const createShortener = (
  { business_code, title, destination, tag },
  callback
) => {
  let isFunc = typeof callback === "function";
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/business/${business_code}/shortener`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { title, destination, tag },
    })
      .then(({ data }) => {
        if (!data.result) return Promise.reject(new Error("result error"));

        return isFunc && callback(null, data);
      })
      .catch((err) => {
        ERROR(`createShortener ${err}`);
        return isFunc && callback(err);
      });
  };
};

export const setBusinessCodeFromSubscribe = (businessCode) => {
  return (dispatch) => {
    dispatch({ type: BUSINESS.SET_BUSINESS_CODE_FROM_SUBSCRIBE, data: businessCode });
  };
}