import { urlValidator } from './validator'

export const genUrlByType = ({ type, value }) => {
  switch (type) {
    case 'line':
      value = `https://line.me/R/ti/p/${value}`
      break
    case 'phone':
      value = `tel:${value}`
      break
    case 'website':
    case 'messenger':
      if (!urlValidator(value)) value = `https://${value}`
      break
    default:
      break
  }
  return value
}





