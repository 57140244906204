module.exports = () => {
  let { REACT_APP_SOCIAL_FACEBOOK, REACT_APP_SOCIAL_LINEOFF, REACT_APP_SOCIAL_TWITTER,
    REACT_APP_SOCIAL_INSTRAGRAM, REACT_APP_SOCIAL_YOUTUBE, REACT_APP_SOCIAL_LINKIN } = process.env

  return {
    facebook: {
      isShow: !!REACT_APP_SOCIAL_FACEBOOK,
      link: REACT_APP_SOCIAL_FACEBOOK,
      icon: 'fa fa-facebook-square fa-2x'
    },
    lineat: {
      isShow: !!REACT_APP_SOCIAL_LINEOFF,
      link: REACT_APP_SOCIAL_LINEOFF,
      icon: 'fas fa-line fa-2x'
    },
    twitter: {
      isShow: !!REACT_APP_SOCIAL_TWITTER,
      link: REACT_APP_SOCIAL_TWITTER,
      icon: 'fa fa-twitter-square fa-2x'
    },
    instagram: {
      isShow: !!REACT_APP_SOCIAL_INSTRAGRAM,
      link: REACT_APP_SOCIAL_INSTRAGRAM,
      icon: 'fa fa-instagram fa-2x'
    },
    youtube: {
      isShow: !!REACT_APP_SOCIAL_YOUTUBE,
      link: REACT_APP_SOCIAL_YOUTUBE,
      icon: 'fa fa-youtube-play fa-2x'
    },
    linkedin: {
      isShow: !!REACT_APP_SOCIAL_LINKIN,
      link: REACT_APP_SOCIAL_LINKIN,
      icon: 'fa fa-linkedin fa-2x'
    }
  }
}