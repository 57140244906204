import { VERIFYOTP } from '../action_const'

let initState = {
    isProcess: false,
    isError: false,
    cooldown: 0,
    error: {},
    refCode: '',
    result: ''
}

export default (state = initState, action) => {
    switch (action.type) {
        case VERIFYOTP.IS_PROCESS:
            return Object.assign({}, state, {
                isProcess: true,
                isError: false,
            })
        case VERIFYOTP.SET_COOLDOWN_OTP:
            return Object.assign({}, state, {
                cooldown: action.data
            })
        case VERIFYOTP.REQUEST_OTP:
            return Object.assign({}, state, {
                isProcess: false,
                isError: false,
                refCode: action.data.refCode || state.refCode || '',
            })
        case VERIFYOTP.REQUEST_OTP_ERROR:
            return Object.assign({}, state, {
                isProcess: false,
                isError: true,
                error: action.data || {}
            })
        default:
            return state
    }
}