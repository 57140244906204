import _ from "lodash";
import { BUSINESS, MEMBER } from "./../action_const";

let initState = {
  isInitailize: true,
  isBusinessProcess: true,
  list: {},
  isProcess: false,
  business_code_from_subscribe: "",
  currentMemberCard: {},
  memberHasCard: false,
};

export default (state = initState, action) => {
  let temp = {};
  switch (action.type) {
    case BUSINESS.SET_BUSINESS_LIST:
      return Object.assign({}, state, {
        isInitailize: false,
        list: _.merge(
          {},
          state.list,
          _.mapKeys(action.data.data, "business_code")
        ),
      });
    case BUSINESS.SUBSCRIBE_BUSINESS:
      temp = state.list;
      if (action.data.result) {
        temp[action && action.business].subscribe_date =
          action.data &&
          action.data.data.length > 0 &&
          action.data.data[0].subscribe_date;
        temp[action && action.business].hide_business =
          action.data &&
          action.data.data.length > 0 &&
          action.data.data[0].hide_business;
      }
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, temp),
        isProcess: false,
      });
    case BUSINESS.BUSINESS_IS_PROCESS:
      return Object.assign({}, state, {
        isBusinessProcess: action.data,
      });
    case BUSINESS.SUBSCRIBE_BUSINESS_IS_START:
      return Object.assign({}, state, {
        isProcess: true,
      });
    case BUSINESS.UNSUBSCRIBE_BUSINESS_IS_START:
      return Object.assign({}, state, {
        isProcess: true,
      });
    case BUSINESS.UNSUBSCRIBE_BUSINESS:
      temp = state.list;
      if (action.data.result) {
        temp[action.business].subscribe_date =
          action.data.data[0].subscribe_date;
        temp[action.business].hide_business = action.data.data[0].hide_business;
      }
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, temp),
        isProcess: false,
      });
    case BUSINESS.ADD_BUSINESS_LIST:
      return Object.assign({}, state, {
        isBusinessProcess: false,
        list: Object.assign({}, state.list, {
          [action.data.data.business_code]: action.data.data,
        }),
      });
    case MEMBER.SET_USER_PERMISSION:
      return Object.assign({}, state, {
        list: {},
      });
    case BUSINESS.FOLLOW_BUSINESS_IS_START:
      return Object.assign({}, state, {
        isProcess: true,
      });
    case BUSINESS.FOLLOW_BUSINESS:
      temp = state.list;
      if (action.data.result)
        temp[action.business].hide_business = action.data.data[0].hide_business;
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, temp),
        isProcess: false,
      });
    case BUSINESS.TOGGLE_NOTIFICATION_BUSINESS:
      temp = state.list;
      let _this_business = Object.assign(
        {},
        temp[action.data.business_code],
        action.data
      );
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, {
          [action.data.business_code]: _this_business,
        }),
      });
    case BUSINESS.SET_BUSINESS_CODE_FROM_SUBSCRIBE:
      return Object.assign({}, state, {
        business_code_from_subscribe: action.data,
      });
    case BUSINESS.SET_CURRENT_MEMBER_CARD:
      return Object.assign({}, state, {
        currentMemberCard: action.data,
      });
    case BUSINESS.SET_MEMBER_HAS_CARD:
      return Object.assign({}, state, {
        memberHasCard: action.data,
      });

    default:
      return state;
  }
};
