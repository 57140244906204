import axios from './../axios_request';
import { PAPI } from './../../libs/_endpoint';
import { REFERENCECODE, MEMBER } from '../action_const';
import { setNextPage2 } from '../../libs/nextPage'
import { getCookie } from '../../libs/cookie';

export const unsubscribeByReference = (code, callback) => {
  return dispatch => {
    dispatch({ type: MEMBER.IS_PROCESS, isProcess: true })
    axios({
      method: 'post',
      url: `${PAPI}/reference/${code}/unsubscribe`
    }).then(() => {
      dispatch({ type: MEMBER.IS_PROCESS, isProcess: false })
      return typeof callback === 'function' && callback()
    }).catch(err => {
      dispatch({ type: MEMBER.IS_PROCESS, isProcess: false })
      return dispatch({ type: MEMBER.SET_USER_PERMISSION, data: { isError: true, isLoading: true } })
    })
  }
}

export const getReferenceDetail = (refCode, history, callback) => {
  return dispatch => {
    dispatch({ type: REFERENCECODE.GET_REFFENENCE_DETAIL_LOADING })
    axios({
      method: 'get',
      url: `${PAPI}/reference/${refCode}`
    }).then(res => {
      let { data, result } = res.data

      if (!result)
        return Promise.reject()

      if (!data.length)
        return history.push('/points')

      if (data[0].ref_expired)
        return history.push(`/b/${data[0].business_code}`)

      if (typeof callback === 'function') callback(data[0].data)
      return dispatch({ type: REFERENCECODE.GET_REFFENENCE_DETAIL, data: data[0].data })

    }).catch(err => {
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: { isError: true, isLoading: true }
      })
    })
  }
}

export const getReferencePath = (refCode, history) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${PAPI}/reference/${refCode}/path`
    }).then(res => {
      let { data, result } = res.data
      if (result && data.length > 0) {
        let { action_name, ref_code, ref_expired, business_code } = data[0]
        let actionPath = decideActionPath({ action_name, ref_code, ref_expired, business_code })
        setNextPage2(actionPath)
      }

      history.push('/points')

      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: { ref: false },
      })
    }).catch(err => {
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: { isError: true, isLoading: true }
      })
    })
  }
}

const decideActionPath = ({ action_name, ref_code, ref_expired, business_code }) => {
  let expectActions = ['send', 'coupon', 'business']
  if (action_name === expectActions[2] || ref_expired) {
    return `/b/${business_code}`
  } else {
    let path = expectActions.indexOf(action_name) > -1 ? 'send' : action_name
    return `/${path}/${ref_code}`
  }
}

export const getBusinessReview = (refCode, callback) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${PAPI}/reference/${refCode}/review`
    })
      .then(({ data }) => {
        typeof callback === 'function' && callback(null, data.result)
        if (data.result)
          return dispatch({ type: REFERENCECODE.SET_BUSINESS_REVIEW, data: data.data })

      }).catch(err => {
        typeof callback === 'function' && callback(err)

      })
  }
}

export const sendBusinessReview = (refCode, review_star, review_message, language = 'th', callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${PAPI}/reference/${refCode}/review`,
      data: {
        review_star: review_star,
        review_message: review_message,
        language
      }
    }).then(res => {
      if (res.data.result) {
        callback()
        dispatch({ type: REFERENCECODE.SET_BUSINESS_REVIEW, data: [{ review_star, review_message }] })
      }
    }).catch(err => {

    })
  }
}

export const getLuckyDrawGame = (refCode, callback) => {
  return dispatch => {
    dispatch({ type: REFERENCECODE.GET_GAMBLE_LOADING })
    axios({
      method: 'get',
      url: `${PAPI}/reference/${refCode}/gamble`,
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.data)
        return dispatch({ type: REFERENCECODE.GET_GAMBLE_DETAIL, data: data.data })
      } else {
        if (typeof callback === 'function') callback(data.error)
        dispatch({ type: REFERENCECODE.GET_GAMBLE_ERROR })
      }
    }).catch((err) => {
      if (typeof callback === 'function') callback(err)
      dispatch({ type: REFERENCECODE.GET_GAMBLE_ERROR })
    })
  }
}

export const gambleLuckyDraw = (refCode, callback) => {
  return dispatch => {
    dispatch({ type: REFERENCECODE.GET_GAMBLE_LOADING })
    axios({
      method: 'post',
      url: `${PAPI}/reference/${refCode}/gambleDraw`,
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.data)
        dispatch({ type: REFERENCECODE.GET_GAMBLE_RESULT, data: data.data })
      } else {
        if (typeof callback === 'function') callback(data.error)
        dispatch({ type: REFERENCECODE.GAMBLE_DRAW_ERROR })
      }
    }).catch((err) => {
      if (typeof callback === 'function') callback(err)
      dispatch({ type: REFERENCECODE.GAMBLE_DRAW_ERROR })
    })
  }
}

export const updateActivateDate = (data) => {
  return dispatch => {
    dispatch({ type: REFERENCECODE.UPDATE_REFFENENCE_DETAIL, data: data })
  }
}

export const getChangePhoneByRefCode = (ref, callback) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${PAPI}/reference/changePhone/${ref}`,
    })
    .then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.data)
        dispatch({ type: REFERENCECODE.SET_CHANGE_PHONE_DATA, data: data.data })
      } else {
        if (typeof callback === 'function') callback(data.error)
      }
    })
    .catch((err) => {
      if (typeof callback === 'function') return callback(err)
    })
  }
}

export const confirmChangePhone = (ref, callback) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${PAPI}/reference/changePhone/${ref}`,
      headers: {
        'Client': getCookie('__cid')
      },
    })
    .then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.result)
      } else {
        if (typeof callback === 'function') callback(data.error)
      }
    })
    .catch((err) => {
      if (typeof callback === 'function') callback(err)
    })
  }
}