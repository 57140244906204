import Cookies from 'js-cookie'
import { DEBUG } from './utils'

import './../libs/Firebase'
Object.assign(window.ps, { fullOption: Cookies.get('__fo') === 'true' })

if (window.matchMedia('(display-mode: standalone)').matches) {
  DEBUG('display-mode is standalone');
}
window.onappinstalled = (evt) => {
  DEBUG('a2hs installed');
};

/*
window.onbeforeinstallprompt = (e) => {
  window.deferredPrompt = e;
  DEBUG('Prompt install')
  setTimeout(launchPromptInstall, 3000)
};
*/
/*
window.ps.launchPromptInstall = launchPromptInstall

function launchPromptInstall() {
  if (!window.deferredPrompt) {
    DEBUG('Not contain deferred prompt')
    return;
  }

  window.deferredPrompt.prompt()
  window.deferredPrompt.userChoice
    .then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        DEBUG('User accepted the A2HS prompt');
      } else {
        DEBUG('User dismissed the A2HS prompt');
      }
      window.deferredPrompt = null;
    });
}
*/