import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import TagManager from "../../libs/TagManager";
import { ActiveIcon, ArrowDown, ContactShow, IconContainer, TitleNavBar } from "./Navbar.style";
import { getGiveawayCoupons, getRewardsList } from "../../redux/actions/action_rewards";

export default function RewardMenu(props) {
  let { business } = props;
  const dispatch = useDispatch();
  const { dictionary } = useSelector((state) => state.language);
  const { giveawayCoupon, reward } = useSelector((state) => state.rewards)
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (business && business.business_code) {
      dispatch(getGiveawayCoupons(business.business_code));
      dispatch(getRewardsList(business.business_code));
    }
  }, [business, dispatch]);

  let menuList = [
    {
      show: Boolean(giveawayCoupon && giveawayCoupon.length),
      type: 'giveaway',
      title: dictionary.giveaway_coupon,
      icon: "icon-diamond"
    },
    {
      show: true,
      type: 'reward',
      title: dictionary.rewards,
      icon: "icon-giftbox",
      style: { fontSize: 18 }
    }
  ]
  let list = menuList.filter((filter) => filter.show);

  const showMenu = (event) => {
    let giveawayShow = Boolean(giveawayCoupon && giveawayCoupon.length);
    let rewardShow = Boolean(reward && reward.length);
    if (giveawayShow && rewardShow) {
      event.preventDefault();
      TagManager.event(`btn-reward_menu_show`)
      setIsOpen(true)
      if (!isOpen)
        document.addEventListener("click", closeMenu);
    } else {
      if (giveawayShow) {
        props.history.push(`/points/${business.business_code}/rewards/giveaway`)
      } else {
        props.history.push(`/points/${business.business_code}/rewards/reward`)
      }
    }
  }

  const closeMenu = (event) => {
    setIsOpen(false)
    document.removeEventListener("click", closeMenu)
  }

  let width = 60 + ((list.length - 1) * 10);

  return (
    <div className="d-flex flex-column align-items-center">
      <div hidden={!isOpen}>
        <ContactShow style={{ width: `${width}vw`, left: `calc(${window.innerWidth > 1024 ? '12.5vw' : `5vw`} + env(safe-area-inset-left))` }}>
          {list.map((value, key) => {
            return (
              <IconContainer
                className="my-2"
                name={value.type}
                key={key}
                width={100 / (list.length)}
                onClick={() => props.history.push(`/points/${business.business_code}/rewards/${value.type}`)}
              >
                <ActiveIcon themeColor={props.themeColor}>
                  <i className={`${value.icon}`} style={list.style} />
                </ActiveIcon>
                <TitleNavBar themeColor={props.themeColor}>{value.title}</TitleNavBar>
              </IconContainer>
            );
          })}
        </ContactShow>
        <ArrowDown style={{ left: 'calc(30vw - 7px + (env(safe-area-inset-left)/2.5))' }} />
      </div>
      <ActiveIcon themeColor={props.themeColor} currentNav={isOpen} onClick={showMenu}>
        <i className="icon-giftbox icon-press" />
      </ActiveIcon>
      <TitleNavBar themeColor={props.themeColor}>{dictionary.ecoupon_free}</TitleNavBar>
    </div>
  )
}