import styled, { css } from 'styled-components'

export const NavbarContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: ${props => props.theme.white_1};
  border-radius: 25px 25px 0px 0px;
  display: grid;
  grid-template-columns: calc(20vw - (env(safe-area-inset-left) / 2.5)) calc(20vw - (env(safe-area-inset-left) / 2.5)) calc(20vw - (env(safe-area-inset-left) / 2.5)) calc(20vw - (env(safe-area-inset-left) / 2.5)) calc(20vw - (env(safe-area-inset-left) / 2.5));
  text-align: center;
  align-items: end;
  padding: 5px 0px;
`

export const NavBarBottom = styled.nav`
  position: fixed;
  overflow: hidden;
  right: 0;
  bottom: env(safe-area-inset-bottom);
  left: 0;
  padding: 0;
  width: calc(100% - (env(safe-area-inset-left) + env(safe-area-inset-right)));
  height: 80px;
  background-color: #fff;
  color: ${props => props.theme.primary};
  border-radius: 25px 25px 0px 0px;
  box-shadow: -10px -10px 24px 0px rgba(0, 0, 0, 0.03);
  margin-left: env(safe-area-inset-left);
`

export const NavbarTitle = styled.div`
  color: ${props => props.theme.secondary};
`

export const TopBar = styled.div`
  z-index: 1020;
  display: flex;
  margin: 0px 15px;
  align-items: center;
`

export const TitleNavBar = styled.div`
  font-size: ${props => props.theme.font_m};
  @media (max-width: 991px) {
    font-size: ${props => props.theme.font_xs};
  }
  @media (max-width: 338px) {
    font-size: 10px;
  }
  color: ${(props) => (props.themeColor ? props.themeColor : props.theme.primary) + '80'};
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.width && css`
    width: calc(${(props) => props.width}%);
  `}
`

export const ActiveIcon = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  color: ${(props) => props.themeColor ? props.themeColor : props.theme.primary};

  ${props => props.currentNav && css`
    background-color: ${(props) => (props.themeColor ? props.themeColor : props.theme.primary) + '0D'};
    font-variation-settings: 'FILL' 1;
  `}
`

export const ContactShow = styled.div`
  position: fixed;
	bottom: calc(93px + env(safe-area-inset-bottom));
  right: calc(5vw + env(safe-area-inset-right));
  min-height: 60px;
  min-width: 250px;
  width: fit-content;
	max-width: min(500px, 90vw);
	background: #fff;
	z-index: 1001;
	border-radius: 10px;
	display: flex;
	align-content: center;
	justify-content: space-around;
	align-items: center;
  padding: 10px;
  box-shadow: 0px 18px 24px 0px rgba(0, 0, 0, 0.05), 0px -10px 24px 0px rgba(0, 0, 0, 0.05);
`

export const ArrowDown = styled.div`
  position: fixed;
  width: 0;
  height: 0;
  bottom: calc(83px + env(safe-area-inset-bottom));
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 12px solid #fff;
  z-index: 1002;
`

export const StarIconBackdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  filter: drop-shadow(0px 3px 4px ${(props) => props.themeColor}66);
`