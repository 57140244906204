import React from "react";
import QRCode from "qrcode.react";
import {
  DropdownContainer,
  DropdownIcon,
  DropdownCard,
  Avatar,
  AvatarBadge,
  ArrowUp,
} from "./Dropdown.style";
import { Title2 } from "../Font";
import TagManager from "../../libs/TagManager";
import default_logo from "../../assets/img/default_avatar.png";
import { useState } from "react";
import { useSelector } from "react-redux";

function DropdownAccount() {
  const { profile: profileList } = useSelector((state) => state.profile);
  const [DropdownShow, setDropdownShow] = useState(false);

  function showMenu(event) {
    event.preventDefault();
    TagManager.event(`btn-profile_show`);
    setDropdownShow(true);
    if (!DropdownShow) {
      document.addEventListener("click", closeMenu);
    }
  }

  function closeMenu(event) {
    setDropdownShow(false);
    document.removeEventListener("click", closeMenu);
  }

  let profile = profileList[0];
  if (!profile) return null;

  let profile_img = profile.photo_uri || default_logo;

  return (
    <DropdownContainer className="position-relative">
      <DropdownIcon onClick={showMenu}>
        <AvatarBadge
          className="d-flex align-items-center justify-content-center"
          src={profile_img}
        />
      </DropdownIcon>

      <ArrowUp hidden={!DropdownShow} />

      <DropdownCard hidden={!DropdownShow}>
        <div
          className="d-flex"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <Avatar
              className="d-flex align-items-center justify-content-center"
              src={profile_img}
            />
            <Title2>
              {profile.first_name} {profile.last_name}
            </Title2>
            <Title2>Pointspot ID : {profile.member_badge_code}</Title2>
            <Title2>
              <i className="icon-light-phone" /> {profile.country_code}{" "}
              {profile.mobile_number}
            </Title2>
          </div>

          <div
            style={{
              display: "flex",
              width: "40%",
              alignItems: "center",
              justifyContent: "center",
              borderLeft: "1px solid #CED4DA",
              padding: "20px",
            }}
          >
            <QRCode
              value={profile.member_badge_code.toString()}
              size={80}
              level={"Q"}
              fgColor="#516373"
            ></QRCode>
          </div>
        </div>
      </DropdownCard>
    </DropdownContainer>
  );
}

export default DropdownAccount;
