import { EMAIL } from "../action_const";

let initState = {
  email: "",
  lang: "",
  refCode: "",
  otp: "",
  invalid_otp: false,
  result: false,
  cooldown: 0, //for resend
  email_exist: null,
  tempMail: "",
  error: "",
  emailSummary: null,
  emailTransaction: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case EMAIL.OTP_EMAIL_REQUEST:
      return Object.assign({}, state, {
        email: action.data.email,
        lang: action.data.lang,
        refCode: action.data.refCode,
      });

    case EMAIL.OTP_EMAIL_REQUEST_FAILED:
      return Object.assign({}, state, {
        email: action.data.email,
        lang: action.data.lang,
        error: action.data.code,
      });

    case EMAIL.SET_RESEND_OTP_COOLDOWN:
      return Object.assign({}, state, {
        cooldown: action.cooldown,
      });

    case EMAIL.OTP_EMAIL_VERIFY:
      return Object.assign({}, state, {
        email: action.data.email,
        refCode: action.data.reference_code,
        otp: action.data.otp_code,
        result: action.data.result,
      });

    case EMAIL.OTP_EMAIL_VERIFY_FAILED:
      return Object.assign({}, state, {
        email: action.data.email,
        error: action.data.code,
      });

    case EMAIL.EMAIL_EXIST:
      return Object.assign({}, state, {
        email_exist: action.data,
      });

    case EMAIL.SET_EMAIL_EXIST_NULL:
      return Object.assign({}, state, {
        email_exist: null,
      });

    case EMAIL.TEMP_MAIL:
      return Object.assign({}, state, {
        tempMail: action.data,
      });

    case EMAIL.SET_RESULT_FALSE:
      return Object.assign({}, state, {
        result: false,
      });

    case EMAIL.ERROR_RESET:
      return Object.assign({}, state, {
        error: "",
      });

    case EMAIL.SET_CONFIG_SETTING_EMAIL:
      return Object.assign({}, state, {
        emailSummary: action.summary,
        emailTransaction: action.transaction,
      });

    case EMAIL.UPDATE_EMAIL_SUMMARY:
      return Object.assign({}, state, {
        emailSummary: action.data,
      });

    case EMAIL.UPDATE_EMAIL_TRANSACTION:
      return Object.assign({}, state, {
        emailTransaction: action.data,
      });

    default:
      return state;
  }
};
