import './libs/appPSinit'
import './libs/appInstall'
import 'font-awesome/css/font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import './color.css'
import 'react-toastify/dist/ReactToastify.min.css'

import * as serviceWorker from './serviceWorker';

import React, { Suspense, lazy } from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import {
  applyMiddleware,
  createStore
} from 'redux'

import { AppMain } from './index.style'
import AppRoute from './AppRoute'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import Reducers from './redux/reducers'
import ReduxPromise from 'redux-promise';
import ReduxThunk from 'redux-thunk'
import { ThemeProvider } from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill';
import { theme } from './style/theme'
import './fontello/css/fontello.css'
import PopupRequestUserInformation from './components/PopupRequestUserInformation'

const PointspotAlertBox = lazy(() => import('./components/PointspotAlertBox'))

smoothscroll.polyfill();

let store = createStore(
  Reducers,
  applyMiddleware(ReduxPromise, ReduxThunk)
)

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppMain className="w-100 h-100">
        <AppRoute />
        <Suspense fallback={<></>}><PointspotAlertBox /></Suspense>
        <Suspense fallback={<></>}><PopupRequestUserInformation /></Suspense>
        <ToastContainer pauseOnHover hideProgressBar autoClose={2000} transition={Slide} closeButton={false} position="bottom-center" />
      </AppMain >
    </ThemeProvider>
  </Provider >,
  document.getElementById('root')
)

serviceWorker.register({
  onUpdate: () => {
    document.getElementById('pointspot-alert-box').style.display = 'flex'
  }
})