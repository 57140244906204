import axios from './../axios_request'
import { PAPI } from '../../libs/_endpoint'
import { ERROR } from '../../libs/utils'
import { VERIFYOTP } from '../action_const'

export const requestOTP = (phoneNumber, callback) => {
    let phoneNumberIFormat = changePhoneNumberFormat(phoneNumber)
    return dispatch => {
        dispatch({ type: VERIFYOTP.IS_PROCESS })
        setTimeout(() => {
            if (!phoneNumberIFormat) {
                if (typeof callback === 'function') callback({ code: 'oauth2/argument-phone-error', message: 'Argument phone error' })
                return dispatch({
                    type: VERIFYOTP.REQUEST_OTP_ERROR,
                    data: { code: 'oauth2/argument-phone-error', message: 'Argument phone error' }
                })
            } else {
                axios({
                    method: 'post',
                    url: `${PAPI}/oauth2/requestOTP`,
                    data: { phoneNumber: phoneNumberIFormat }
                }).then(({ data }) => {
                    if (!data.result) {
                        if (typeof callback === 'function') callback(data.error || 'res error', null)
                        dispatch({ type: VERIFYOTP.REQUEST_OTP_ERROR, data: data.error })
                        ERROR('Error response', data.error)
                    } else {
                        cooldown(dispatch, new Date().getTime())
                        if (typeof callback === 'function') callback(null, data.data)
                        dispatch({ type: VERIFYOTP.REQUEST_OTP, data: data.data })
                    }
                }).catch(err => {
                    ERROR('Error OTP catch', err)
                    if (typeof callback === 'function') callback({ code: 'oauth2/operation-error', massage: 'Operation error' })
                    dispatch({
                        type: VERIFYOTP.REQUEST_OTP_ERROR,
                        data: { code: 'oauth2/operation-error', massage: 'Operation error' }
                    })
                })
            }
        }, 500)
    }
}

export function cooldown(dispatch, requestTime) {
    let cooldown_time = 60
    let cooldown = cooldown_time
    let _time = parseInt(new Date().getTime() / 1000)
    requestTime = parseInt(requestTime / 1000)

    cooldown = (requestTime + cooldown) > _time ? requestTime + cooldown - _time : 0

    window.ps.process_cooldown && clearInterval(window.ps.process_cooldown)

    dispatch({ type: VERIFYOTP.SET_COOLDOWN_OTP, data: cooldown })
    window.ps.process_cooldown = setInterval(() => {
        cooldown = cooldown - 1
        if (cooldown < 0) return clearInterval(window.ps.process_cooldown)
        dispatch({ type: VERIFYOTP.SET_COOLDOWN_OTP, data: cooldown })
    }, 1000)
}

function changePhoneNumberFormat(phoneNumber) {
    let countryCode = '+66'
    return String(phoneNumber).length === 10
        ? String(phoneNumber).replace(/^0/g, countryCode)
        : phoneNumber
}
