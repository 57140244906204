import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, withRouter } from 'react-router-dom'

import { getBusinessList } from './redux/actions/action_business'
import { getReferenceDetail } from './redux/actions/action_refcode'
import { signInWithReference } from './redux/actions/action_login'

import HOC_loading_2 from './HOC/HOC_loading_2'

class AppReferenceRoute extends Component {
  async componentDidMount() {
    let { computedMatch: { params: { ref } } } = this.props
    if (!ref)
      return this.props.history.push('/points')

    await new Promise((resolve, reject) => {
      this.props.signInWithReference(ref, (err, data) => {
        if (!Boolean(err)) {
          this.props.getReferenceDetail(ref, this.props.history)
          this.props.getBusinessList() // for subscribe
          resolve(data)
        } else {
          reject(err)
        }
      })
    })
  }

  render() {
    let { store_refcode } = this.props
    let { store_language: { dictionary } } = this.props
    let { store_permission, store_permission: { isError } } = this.props
    let isLoading = store_refcode.isLoading || store_permission.isLoading

    return <AppRouteWithLoading
      {...this.props}
      isError={isError}
      isLoading={isLoading}
      dictionary={dictionary} />
  }
}

class MainAppReferenceRoute extends Component {
  render() {
    let { component: ChildComponent } = this.props

    return <Route render={() => <ChildComponent {...this.props} />} />
  }
}

const AppRouteWithLoading = HOC_loading_2(MainAppReferenceRoute)

const mapStateToProps = state => {
  return {
    store_permission: state.permission,
    store_refcode: state.refcode,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    signInWithReference,
    getReferenceDetail,
    getBusinessList
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppReferenceRoute))
