import { DEBUG } from "./utils";
import { setCookie, getCookie, removeCookie } from './cookie';

const used_cookie = true;
const expired_cookie = { expires: 1 }

export function getNextPage() {
  const nextPage = used_cookie ? getCookie('nextPage') : localStorage.nextPage;
  return nextPage
}

export function setNextPage2(pagePath) {
  if (!pagePath) return
  if (used_cookie)
    setCookie('nextPage', pagePath, expired_cookie);
  else
    localStorage.nextPage = pagePath;
}


export function setNextPage(props_history, pagePath) {
  let new_path = props_history.location.pathname.replace(/^\/(b|business)\//g, '/points/')
  if (!new_path) return
  DEBUG('Set Next Page', new_path);
  if (used_cookie)
    setCookie('nextPage', new_path, expired_cookie);
  else
    localStorage.nextPage = new_path;
}

export function requestGotoNextPage(props_history) {
  DEBUG(props_history.location.pathname, ': Request Goto NextPage', getCookie('nextPage'), localStorage.nextPage);

  const nextPage = used_cookie ? getCookie('nextPage') : localStorage.nextPage;

  if (nextPage !== undefined && nextPage !== '') {
    DEBUG(props_history.location.pathname, ': Request push history NextPage', localStorage.nextPage);

    props_history.push(nextPage);

    DEBUG(props_history.location.pathname, ': Request debounce remove NextPage', getCookie('nextPage'), localStorage.nextPage);

    if (window['location'].pathname === nextPage) {
      removeCookie('nextPage');
      delete localStorage.nextPage;
    }

    return true;
  } else {
    return false;
  }
}

export function removeNextPage() {
  if (used_cookie)
    removeCookie('nextPage');
  else
    delete localStorage.nextPage;
}