import { IP } from "../action_const";

let initState = {
  ip: "",
  country: "",
  isLoading: true,
};

export default (state = initState, action) => {
  switch (action.type) {
    case IP.SET_INFO_FROM_IP:
      return Object.assign({}, state, {
        ip: action.ip,
        country: action.country,
      });

    case IP.SET_IS_LOADING:
      return Object.assign({}, state, {
        isLoading: action.data,
      });

    default:
      return state;
  }
};
