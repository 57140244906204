import styled, { css } from 'styled-components'

export const SideMenuMain = styled.div`
background-color: #516373;
`

export const SideMenu = styled.div`
  min-width: min(300px, 80vw);
`

export const SideMenuHeader = styled.div`
  color: ${props => props.theme.primary};
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid ${props => props.theme.background_secondary};
`

export const SideMenuItem = styled.div`
  font-size:1.2rem;
  cursor: pointer;
  aborder-bottom: 2px solid ${props => props.theme.background_secondary};
`

export const SideMenuLogo = styled.img`
  max-width: 200px !important;
`

export const SideMenuIcon = styled.i`
  ${props => props.selected
    ? css`color: ${props => props.theme.primary};`
    : css`color: ${props => props.theme.primary + 'AA'};`
  }
  font-size: 19px;
`

export const SideMenuLink = styled.div`
  padding: 15px 25px 15px 25px;
  display: block;
`

export const SideFooterText = styled.span`
  color: ${props => props.theme.primary};
`

export const BadgeNew = styled.div`
  font-size: .7rem !important;
  border-radius: 40% !important;
`

export const LineHorizontal = styled.div`
  width: 75px;
  border-top: 1px solid ${props => props.theme.primary};
  margin: 15px 0;
`

export const LineVertical = styled.div`
  border-left: 1px solid ${props => props.theme.primary};
  margin: 0 5px;
`

export const LanguageTitle = styled.div`
  font-size: 16px;
  color: ${props => props.theme.primary};
  @media (max-width: 991px) {
    font-size: 14px;
  }
  ${props => props.bold && css`
    font-weight: ${props.theme.font_bold};
  `}
`