import React from 'react';
import Logo from '../assets/img/m-logo-96.png';
import { GlobalConfig } from '../config';

export default Present =>
  class extends React.Component {
    render() {
      let { dictionary } = this.props
      let { Application, isMaintenance } = GlobalConfig
      let _style = { top: 0, left: 0, position: 'fixed', height: 'calc( 100% )', background: 'white', zIndex: '999' }

      return this.props.isLoading || this.props.isError || isMaintenance
        ? <div className='w-100 d-flex align-items-center justify-content-center' style={_style}>
          <div className='w-100 text-center' style={{ color: '#516373' }}>
            <p>
              <img src={Logo} alt="Pointspot" className="img-fluid" />
            </p>
            {
              isMaintenance
                ? <div className='p-2'>
                  <span style={{ color: '#556677' }}>
                    {dictionary.maintenance}
                    <br />
                    {dictionary.maintenanceDetail}
                  </span>
                </div>
                : this.props.isError
                    ? <div className='p-2'>
                      {
                        Boolean(Application && Application.version)
                          ? <div style={{ fontSize: '12px', paddingBottom: '10px' }}>{`${Application.name} ${Application.version}`}</div>
                          : null
                      }
                      <span style={{ color: '#556677' }}>
                        {dictionary.cannotConnectServer}
                        <br />
                        {
                          !this.props.hiddenReload
                          && <a href="/">{`${dictionary.reload}`}</a>
                        }
                        {this.props.errorMessage ? this.props.errorMessage : null}
                      </span>
                    </div>
                    : <p>
                      <i className="fa fa-spinner fa-pulse fa-1x" />
                    </p>
            }
          </div>
        </div>
        : <Present {...this.props} />
    }
  }