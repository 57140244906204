import { CUSTOM_FORM } from '../action_const'

let initState = {
  form_setting: [],
  form_info: [],
  loading_form: false,
  loading_form_info: false,
  form_error: '',
}

export default (state = initState, action) => {
  switch (action.type) {
    case CUSTOM_FORM.LOADING_FORM:
      return Object.assign({}, state, { loading_form: true })
    case CUSTOM_FORM.GET_CUSTOM_FORM_TITLE: 
      return Object.assign({}, state, { form_setting: action.data, loading_form: false })
    case CUSTOM_FORM.LOADING_FORM_INFO:
      return Object.assign({}, state, { loading_form_info: true })
    case CUSTOM_FORM.SET_FORM_INFO:
      return Object.assign({}, state, { form_info: action.data, loading_form_info: false, form_error: action.error })
    default:
      return state
  }
}
