import { MEMBER } from '../action_const'


export const setUserPermission = (data) => {
  return { type: MEMBER.SET_USER_PERMISSION, data: data }
}

export const setRedirectPath = (data) => {
  return { type: MEMBER.SET_REDIRECT_PATH, data: data }
}

// export const setMemberLogin = () => {
//   return { type: MEMBER.MEMBER_LOGIN }
// }

// export const setMemberLogout = () => {
//   return { type: MEMBER.MEMBER_LOGOUT }
// }

// export const setMemberRegister = () => {
//   return dispatch => {
//     dispatch({ type: MEMBER.SET_MEMBER_REGISTER })
//   }
// }

export const setMemberRef = () => {
  return { type: MEMBER.SET_MEMBER_REF_CODE }
}






