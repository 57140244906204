import { PAGE } from "../action_const";

let initState = {
  title: '',
  toggle: true,
  only: false,
  backward: '',
  hidden: false,
  isShow: false,
  hiddenProfile: false,
  hiddenLang: false,
  isBusiness: false,
  isBusinessArchive: false,
  blockNoti: '',
  themeColor: '',
}

export default (state = initState, action) => {
  switch (action.type) {
    case PAGE.SET_PAGE_NAV:
      return Object.assign({}, state, action.data)
    case PAGE.SHOW_DROPDOWN:
      return Object.assign({}, state, {
        isShow: true
      })
    case PAGE.HIDE_DROPDOWN:
      return Object.assign({}, state, {
        isShow: false
      })
    default:
      return state
  }
}

