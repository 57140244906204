import { TRANSFERPOINT } from "../action_const";

let initState = {
  isProcess: false,
  isError: false,
  data: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case TRANSFERPOINT.START_TRANSFER_TO:
      return Object.assign({}, state, { isProcess: true, isError: false, data: {} })
    case TRANSFERPOINT.END_TRANSFER_TO:
      return Object.assign({}, state, { isError: false, isProcess: false, data: action.payload })
    case TRANSFERPOINT.TRANSFER_HAVE_ERROR:
      return Object.assign({}, state, { isError: true, isProcess: false, data: {} })
    default:
      return state
  }
}

