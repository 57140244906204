import { LANGUAGE } from '../action_const'
import { getCookie } from '../../libs/cookie'

const dictionaryList = ['en', 'th']

export const selectLang = (lang) => {
  let result = getBrowserLang()
  result.lang = lang || getCookie('__lang') || result.lang || 'th'
  return dispatch => {
    dispatch({ type: LANGUAGE.SELECT_LANGUAGE, data: result });
  };
};

function getBrowserLang() {
  let result = { lang: '', languages: dictionaryList }
  if (!navigator.language && !navigator.languages) return result;

  let langList = [navigator.language, ...navigator.languages]
  langList.map(v => v.split('-')[0])
  let findLang = langList.filter(v => dictionaryList.find(d => d === v))

  result.lang = null; // Ignore Browser lang findLang[0]
  result.languages = findLang

  return result;
}