import React, { useState, useEffect } from "react";
import "./OtpSection.css";
import OtpInput from "react-otp-input";

function OtpSection(props) {
  let { updateOtp, otp } = props;
  const [thisotp, setOtp] = useState(otp);

  useEffect(() => {
    setOtp(otp)
  }, [otp])

  function otpChange(newvalue) {
    setOtp(newvalue);
    updateOtp(newvalue);
  }

  return (
    <div>
      <OtpInput
        containerStyle="otp-input"
        value={thisotp}
        onChange={otpChange}
        numInputs={5}
        inputStyle="number"
        isInputNum={true}
      />
    </div>
  );
}

export default OtpSection;
