import { MYCOUPONS } from "../action_const";
import _ from 'lodash'

let initState = {
  isLoading: true,
  list: [],
  coupons: [],
  rewards: {},
  giveaway_coupon: [],
  new_my_coupon: {},
}

export default (state = initState, action) => {
  switch (action.type) {
    case MYCOUPONS.LOADING_COUPONS_LIST:
      return Object.assign({}, state, { isLoading: true })
    case MYCOUPONS.GET_COUPONS_LIST:
      let couponList = action.data.filter(value => (value.type === 'coupon'))
      couponList = couponList[0].data
        .reduce((result, item) => {
          const key = item.business_code;
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(item);
          return result;
        }, {})
      let rewardList = action.data.filter(value => (value.type === 'reward'))
      rewardList = rewardList[0].data
        .reduce((result, item) => {
          const key = item.business_code;
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(item);
          return result;
        }, {})
      return Object.assign({}, state, {
        coupons: {...couponList},
        rewards: {...rewardList},
        isLoading: false
      })
    case MYCOUPONS.CLEAR_COUPONS_STATE:
      return initState
    case MYCOUPONS.NEW_MY_COUPON:
      let new_my_coupon_by_biz = {}

      if ((action.data && action.data.type === 'removed') && _.isEmpty(state.new_my_coupon))
        return state

      if (action.data && action.data.new_coupon && action.data.new_coupon.length > 0)
        action.data.new_coupon.map(val => new_my_coupon_by_biz[val.business_code] = { ecard_list: val.ecard_list })

      return Object.assign({}, state, { new_my_coupon: new_my_coupon_by_biz })
    case MYCOUPONS.REMOVE_NEW_MY_COUPON:
      if (action.data) {
        delete state.new_my_coupon[action.data]
        return state
      } else
        return Object.assign({}, state, { new_my_coupon: {} })
    default:
      return state
  }
}

