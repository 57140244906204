export const urlValidator = (url) => {
  try { return new URL(url) }
  catch (err) { return false }
}

export const pointsValidator = (params) => {
  if (!params) return false;
  let { points } = params
  points = (parseInt(points || '')).toString()
  return Boolean(points.trim().match(/^\d+$/g))
}
export const receiverValidator = (params) => {
  if (!params) return false;
  let { receiver } = params
  return Boolean(receiver && receiver.trim().match(/^(0\d{9}|((?!(0))[0-9])\d{5})$/g))
}
export const csvFileValidator = ({ name }) => {
  return Boolean(name && name.match(/\.csv$/g))
}
export const verifyPhone = (phone) => {
  return Boolean(phone && phone.trim().match(/^(0[16-9]\d{8}|\+\d{11}|\+\d{10}|0[2-5]\d{7})$/g))
}
export const verifyMobilePhone = (phone) => {
  return Boolean(phone && phone.trim().match(/^(0[16-9]\d{8}|\+\d{11})$/g))
}
export const verifyNumber = (phone) => {
  return Boolean(phone && phone.trim().match(/^(\d[0-9]{3,10}?)$/g))
}
export const verifyEmail = (email) => {
  return Boolean(email && email.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g))
}
export const verifyIDcard = (ID) => {
  return Boolean(ID && ID.replace(/[\W\D\s_]+/g, '').match(/^(\d{13})$/g))
}
export const verifyZipcode = (code) => {
  return Boolean(code && code.trim().match(/^(\d{5})$/g))
}