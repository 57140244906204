import React, { Component } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setRedirectPath } from "./redux/actions/action_permission";

class AppPrivateRoute extends Component {
	componentDidMount() {
		this.props.setRedirectPath(this.props.location.pathname);
	}

	render() {
		const {
			component: ChildComponent,
			computedMatch: {
				params: { id },
			},
			store_permission,
			store_business,
			homepage,
		} = this.props;
		return (
			<Route
				render={(rest) =>
					store_permission.auth && store_permission.register ? (
						!store_business.list[id]?.archive_date || homepage ? ( //archived business show only homepage
							<ChildComponent {...this.props} {...rest} />
						) : (
							<Redirect to={`/points/${id}`} /> //redirect to homepage
						)
					) : store_permission.auth && !store_permission.register ? (
						<Redirect to={"/register"} />
					) : (
						<Redirect to={"/login"} />
					)
				}
			/>
		);
	}
}

const mapStateToProps = ({ business, permission }) => {
	return {
		store_business: business,
		store_permission: permission,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setRedirectPath,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppPrivateRoute));
