import React from 'react';
import parser from 'bbcode-to-react';

export function nl2br(message) {
  message = message || '';
  message = message.replace(/\\n/g, '\n')
  return message
    ? message.split('\n').map((line, idx) =>
      <span key={idx}>{parser.toReact(line)}<br /></span>)
    : '';
};
