import { LOGIN, MEMBER } from "../action_const";
import { getNowDate } from "./../../libs/date";

let initState = {
  isProcess: false,
  isError: false,
  cooldown: 0,
  error: {},
  todo: "signinPhone",
  synctime: 0,
  line: null,
  signinFromLine: false,
  isEmailVerified: null,
  memberBadgeCode: null,
  selectAccount: false,
  accountList: [],
  profileForLogin: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case LOGIN.IS_PROCESS:
      return Object.assign({}, state, {
        isProcess: action.isProcess,
        isError: false,
      });

    case LOGIN.IS_RESEND_OTP:
      return Object.assign({}, state, {
        isProcess: action.isProcess,
        todo: "signinPhone",
      });

    case LOGIN.SET_RESEND_OTP_COOLDOWN:
      return Object.assign({}, state, {
        cooldown: action.cooldown,
      });

    case LOGIN.PHONE_REQUEST_OTP:
      let data = action.data || {};
      return Object.assign({}, state, {
        isProcess: action.isProcess || false,
        OTPData: data,
        refCode: data.refCode || state.refCode,
        todo: !action.isError ? "verifyOTP" : "signinPhone",
        isError: action.isError || false,
        error: action.error || {},
      });

    case LOGIN.VERIFY_OTP:
      return Object.assign({}, state, {
        isProcess: action.isProcess || false,
        isError: action.isError || false,
        error: action.error || {},
      });

    case LOGIN.CANCEL_OTP:
    case MEMBER.SET_USER_PERMISSION:
      return Object.assign({}, state, {
        isProcess: false,
        OTPData: null,
        refCode: null,
        todo: "signinPhone",
        isError: false,
        error: {},
      });

    case LOGIN.SYNCTIME:
      window.ps.synctime = action.data.synctime;
      window.ps.getNowDate = getNowDate;
      return Object.assign({}, state, {
        synctime: action.data.synctime,
      });

    case LOGIN.SET_LINE_USER_INFO:
      return Object.assign({}, state, {
        line: {
          lineUserInfo: action.lineUserInfo,
          businessCode: action.businessCode,
        },
      });

    case LOGIN.SIGN_IN_FROM_LINE:
      return Object.assign({}, state, {
        signinFromLine: true,
      });

    case LOGIN.SET_TODO_SEND_OTP_VIA_EMAIL:
      return Object.assign({}, state, {
        todo: action.data,
      });

    case LOGIN.SET_IS_EMAIL_VERIFIED:
      return Object.assign({}, state, {
        isEmailVerified: action.data,
        memberBadgeCode: action.memberBadgeCode,
        selectAccount: action.selectAccount || false,
        accountList: action.accountList || [],
      });

    case LOGIN.SET_PROFILE_FOR_LOGIN:
      return Object.assign({}, state, {
        profileForLogin: [...state.profileForLogin, action.data],
      });

    default:
      return state;
  }
};
