import styled, { css } from 'styled-components'

const fontStyle = styled.div`
  
  color: ${props => props.primary ? props.theme.primary
    : props.primary_2 ? props.theme.primary + 'AA'
      : props.secondary ? props.theme.secondary
        : props.secondary_3 ? props.theme.secondary_3
          : props.white ? props.theme.white
            : props.dark ? props.theme.dark
              : props.danger ? props.theme.red
                : props.success ? props.theme.green
                  : props.warning ? props.theme.orange
                    : props.disabled ? props.theme.disabled
                      : props.green_deep ? props.theme.green_deep
                        : props.gold ? props.theme.gold
                          : props.blue ? props.theme.blue
                            : props.rp_leaf ? props.theme.rp_leaf
                              : props.gray ? props.theme.gray
                                : props.theme.primary
  };

  ${props => props.bold && css`
    font-weight: ${props.theme.font_bold};
  `}
 
  ${props => props.flex && css`
    display: flex;
    align-items: center;
  `}

   ${props => props.link && css`
       cursor: pointer;
       &:hover{
         opacity:0.9;
       }
  `}
  
  ${props => props.center && css`
  text-align: center;
`}
`
export const Title = styled(fontStyle)`
   font-size: ${props => props.theme.font_xl};
  @media (max-width: 991px) {
    font-size: ${props => props.theme.font_l};
  }
`

export const Title1 = styled(fontStyle)`
   font-size: ${props => props.theme.font_l};
  @media (max-width: 991px) {
    font-size: ${props => props.theme.font_m};
  }
`

export const Title2 = styled(fontStyle)`
  font-size: ${props => props.theme.font_m};
  @media (max-width: 991px) {
    font-size: ${props => props.theme.font_s};
  }
`

export const Title3 = styled(fontStyle)`
  font-size: ${props => props.theme.font_s};
  @media (max-width: 991px) {
    font-size: ${props => props.theme.font_xs};
  }
`
export const Title4 = styled(fontStyle)`
  font-size: ${props => props.theme.font_xs};
  @media (max-width: 991px) {
    font-size: ${props => props.theme.font_xxs};
  }
`