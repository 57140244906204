import { TOTP } from "../action_const";

let initState = {
  token: "",
  timeRemain: 60,
  timeOut: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case TOTP.GET_TOKEN:
      return Object.assign({}, state, {
        token: action.data,
      });
    case TOTP.GET_TIMEREMAIN:
      return Object.assign({}, state, {
        timeRemain: action.data,
      });
    case TOTP.TIMEOUT:
      return Object.assign({}, state, {
        timeOut: true,
      });
    default:
      return state;
  }
};
