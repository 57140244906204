const package_config = require('./../../package.json')
const { REACT_APP_IS_MAINTENANCE } = process.env

export const GlobalConfig = {
  Application: {
    name: package_config.appDisplay,
    version: package_config.version
  },
  organization: require('./getConfig/getOrganization')(),
  document: require('./getConfig/getDocuments')(),
  socials: require('./getConfig/getSocial')(),

  firebase: require('./getConfig/getFirebase')(),

  preference: require('./getConfig/getPreference')(),

  recaptcha: require('./getConfig/getRecaptcha'),

  package: package_config,
  isMaintenance: REACT_APP_IS_MAINTENANCE
};
