import { LANGUAGE } from '../action_const'
import dictionary from '../../assets/lang'
import moment from 'moment'
import 'moment/locale/th'
import { getCookie, setCookie } from '../../libs/cookie'

let defaultLocale = getCookie('__lang') || 'th'
moment.locale(defaultLocale)

let initState = {
  language: defaultLocale,
  dictionary: dictionary[defaultLocale] || dictionary.th
}

export default (state = initState, action) => {
  switch (action.type) {
    case LANGUAGE.SELECT_LANGUAGE:
      const lang = action.data.lang
      moment.locale(lang)
      setCookie('__lang', lang)
      return Object.assign({}, state, {
        language: lang,
        dictionary: dictionary[lang] || dictionary.th
      })
    default:
      return state
  }
}