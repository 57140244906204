import axios from './../redux/axios_request'
import { getCookie } from '../libs/cookie'

const { PAPI } = require('./_endpoint');

export const getUserProfile = () => {
  return axios({
    method: 'get',
    url: `${PAPI}/az/profile`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
  })
}

export const createUserProfile = (data) => {
  return axios({
    method: 'post',
    url: `${PAPI}/az/profile`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: data
  })
}

export const updateUserProfile = (data) => {
  return axios({
    method: 'put',
    url: `${PAPI}/az/profile`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: data
  })
}

export const updateProfileLineId = (data) => {
  return axios({
    method: 'put',
    url: `${PAPI}/az/business/${data.businessCode}/profile/line`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: {
      lineId: data.lineId,
      lineDisplayName: data.lineDisplayName,
    }
  })
}

export const removeLineLiff = (data) => {
  return axios({
    method: 'delete',
    url: `${PAPI}/az/profile/line`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: data
  })
}

export const updateUserAvatar = (data) => {
  return axios({
    method: 'put',
    url: `${PAPI}/az/profile`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: data
  })
}

export const redeemPointsAPI = (rewardCode, businessCode, body = null, callback) => {
  return axios({
    method: 'post',
    url: `${PAPI}/az/business/${businessCode}/redeem/${rewardCode}`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: body
  }).then(({ data }) => {
    if (data.result) {
      if (typeof callback === 'function') callback(null, data)
    } else {
      if (typeof callback === 'function') callback(data)
    }

  }).catch((error) => {
    if (typeof callback === 'function') callback(error)

  })
}

export const checkNewPhone = (phoneNumber) => {
  return axios({
    method: 'post',
    url: `${PAPI}/az/profile/checkNewPhone`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: { phoneNumber: phoneNumber }
  })
}

export const checkNewPhoneNumber = (phoneNumber) => {
  return axios({
    method: 'post',
    url: `${PAPI}/az/profile/checkPhoneNumber`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: { phoneNumber: phoneNumber }
  })
}

export const changeNewPhone = (phoneNumber, refCode, otp, token, countryCode) => {
  return axios({
    method: 'post',
    url: `${PAPI}/az/profile/changeNewPhone`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: { phoneNumber, refCode, otp, token, countryCode }
  })
}

export const memberAddress = (method, data) => {
  return axios({
    method: method,
    url: `${PAPI}/az/profile/address`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: data
  })
}

export const insertCustomForm = (data) => {
  return axios({
    method: 'post',
    url: `${PAPI}/az/business/${data.businessCode}/customForm`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: data
  })
}

export const updateLineDisplayName = (data) => {
  return axios({
    method: 'put',
    url: `${PAPI}/az/profile/updateProfileLineDisplayName`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: data
  })
}

export const updateNotificationSetting = (data) => {
  return axios({
    method: 'put',
    url: `${PAPI}/az/profile/updateNotificationSetting`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: data
  })
}
