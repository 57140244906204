import axios from "./../axios_request";
import { PAPI } from "./../../libs/_endpoint";
import { ERROR } from "./../../libs/utils";
import { getCookie } from "../../libs/cookie";
import { CUSTOM_FORM } from "../action_const";

export const getCustomFormTitle = (business_code, callback) => {
  return (dispatch) => {
    dispatch({ type: CUSTOM_FORM.LOADING_FORM })
    axios({
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      method: "get",
      url: `${PAPI}/az/business/${business_code}/customForm`,
    })
      .then(({ data }) => {
        if (typeof callback === "function") callback(null, data.data);
        dispatch({ type: CUSTOM_FORM.GET_CUSTOM_FORM_TITLE, data: data.data });
      })
      .catch((err) => {
        if (typeof callback === "function") callback(err);
        ERROR(err);
      });
  };
};

export const getBusinessFormValue = (business_code, memberBadgeCode, callback) => {
  return (dispatch) => {
    dispatch({ type: CUSTOM_FORM.LOADING_FORM_INFO })
    axios({
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      method: "post",
      url: `${PAPI}/az/business/${business_code}/customForm/formInfo`,
      data: {
        memberBadgeCode
      }
    })
      .then(({ data }) => {
        if (typeof callback === 'function') callback(null, data.data)
        return dispatch({ type: CUSTOM_FORM.SET_FORM_INFO, data: data.data });
      })
      .catch((err) => {
        if (typeof callback === "function") callback(err);
        ERROR(err);
      });
  };
};

export const insertCustomForm = (business_code, memberBadgeCode, value, callback) => {
  return (dispatch) => {
    axios({
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      method: 'post',
      url: `${PAPI}/az/business/${business_code}/customForm`,
      data: {
        businessCode: business_code,
        customFormValue: value,
        member_badge_code: memberBadgeCode
      }
    })
      .then(({ data }) => {
        if (typeof callback === 'function') callback(null, data.data)
        return dispatch({ type: CUSTOM_FORM.SET_FORM_INFO, data: data.data, error: data.error || '' })
      })
      .catch((err) => {
        if (typeof callback === "function") callback(err);
        ERROR(err);
      });
  }
}