import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const HeadTitle = styled.div`
  font-size: 18px;
`;

export const DataHeader = styled.div`
  font-size: 16px;
  align-self: center;
  color: #516373;
`;

export const DataTitle = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: #516373;
  margin-top: 10px;
`;

export const SaveButton = styled.button`
  width: 128px;
  height: 39px;
  margin-top: 20px;
  align-self: center;

  background-color: #516373;
  border-radius: 0.25rem;
  color: #ffffff;
  border: none;
  cursor: pointer;

  &:hover:enabled {
    opacity: 0.9;
  }

  &:disabled {
    background-color: #dde3ed;
    border-radius: 0.25rem;
    color: #bfcadc;
    border: none;
    cursor: not-allowed;
  }
`;

export const LanguageSection = styled.div`
  width: fit-content;
  align-self: flex-end;
  margin-bottom: 10px;
`;
