import axios from "./../axios_request";
import { MYCOUPONS, MEMBER } from "../action_const";
import { PAPI } from "./../../libs/_endpoint";
import { getCookie } from "../../libs/cookie";
import { fbFirestore } from "./../../libs/Firebase";
import { ERROR, DEBUG } from "../../libs/utils";
import moment from "moment";
import { doc, onSnapshot, deleteDoc, getDocs, collection } from "firebase/firestore";

export const getRewards = (callback) => {
	return (dispatch) => {
		dispatch({ type: MYCOUPONS.LOADING_COUPONS_LIST })
		axios({
			method: "get",
			url: `${PAPI}/az/coupons`,
			headers: {
				Authorization: `Bearer ${getCookie("__a")}`,
				Client: getCookie("__cid"),
			},
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data.data);
					return dispatch({ type: MYCOUPONS.GET_COUPONS_LIST, data: data.data });
				} else {
					if (typeof callback === "function") callback("error");
				}
			})
			.catch((err) => {
				if (typeof callback === "function") callback("error");
				return dispatch({
					type: MEMBER.SET_USER_PERMISSION,
					data: {
						isError: true,
						isLoading: true,
					},
				});
			});
	};
};

export const clearMyCouponsState = () => {
	return (dispatch) => {
		dispatch({ type: MYCOUPONS.CLEAR_COUPONS_STATE });
	};
};

export const onSnapShotMyCoupon = (member_badge_code, callback) => {
	return (dispatch) => {
		if (!member_badge_code) return null;

		let data = document[member_badge_code];
		if (data) return;

		const newCouponRef = collection(fbFirestore, "profile", `${member_badge_code}`, "new_coupon");
		onSnapshot(
			newCouponRef,
			(snapshot) => {
				let bizNewCoupon = [];
				let change_type = "";

				if (snapshot) {
					snapshot.docChanges().forEach((change) => {
						change_type = change.type || "";
					});
					snapshot.forEach((data) => (data && data.data() ? bizNewCoupon.push(data.data()) : null));
				}

				if (typeof callback === "function") callback(null, bizNewCoupon);

				if (!bizNewCoupon || !bizNewCoupon.length)
					return setTimeout(() => dispatch({ type: MYCOUPONS.NEW_MY_COUPON }), 200);

				let create_date_list = bizNewCoupon
					.filter((f) => f.create_date)
					.map((v) => new Date(v.create_date.toDate()).getTime())
					.sort();
				let min_time = Math.min(...create_date_list) || (create_date_list && create_date_list[0]) || "";
				let start_time = moment(min_time).startOf("hour").toDate();

				setTimeout(() => {
					axios({
						method: "get",
						url: `${PAPI}/az/coupons?start_date=${new Date(start_time).getTime()}`,
						headers: { Authorization: `Bearer ${getCookie("__a")}`, Client: getCookie("__cid") },
					})
						.then(({ data }) => {
							let my_coupon_id_list = [];
							let coupon_list = data.data;

							if (!data.result) return Promise.reject(new Error("result error"));

							if (!coupon_list || !coupon_list.length) return null;

							coupon_list.map((v) => v.data && v.data.map((v2) => my_coupon_id_list.push(v2.trans_id)));
							let new_coupon =
								my_coupon_id_list.length > 0
									? bizNewCoupon
											.map((val) =>
												Object.assign({}, val, {
													ecard_list:
														val.ecard_list && val.ecard_list.length > 0
															? val.ecard_list.filter((f) => my_coupon_id_list.indexOf(f.ecard_id) > -1)
															: [],
												})
											)
											.filter((f) => f.ecard_list.length > 0)
									: [];

							return dispatch({ type: MYCOUPONS.NEW_MY_COUPON, data: { new_coupon: new_coupon, type: change_type } });
						})
						.catch((err) => ERROR("onSnapshot new_coupon getCoupon", err));
				}, 100);
			},
			(error) => ERROR("onSnapshot path new_coupon", error)
		);

		document[member_badge_code] = doc;
	};
};

export const removeNewMyCoupon = (member_badge_code, business_code) => {
	if (!member_badge_code) return null;

	return async (dispatch) => {
		DEBUG("removeNewMyCoupon", member_badge_code, Boolean(business_code) ? business_code : "all");

		if (Boolean(business_code)) {
			const bizNewCouponRef = doc(fbFirestore, "profile", `${member_badge_code}`, "new_coupon", business_code);
			await deleteDoc(bizNewCouponRef);
		} else {
      const newCouponRef = collection(fbFirestore, "profile", `${member_badge_code}`, "new_coupon");
			const newCoupon = await getDocs(newCouponRef); 
      newCoupon.forEach(async (element) => await deleteDoc(element.ref));
		}

		return dispatch({ type: MYCOUPONS.REMOVE_NEW_MY_COUPON, data: business_code });
	};
};
