import { MEMBER } from "../action_const";

let initState = {
  isError: false,
  errorMessage: {},
  isProcess: false,
  profile: [],
  memberBadgeCode: "",
  phoneNumber: "",
  address: [],
  total_address: 0,
  updateProfileSuccess: null,
  isLoadingUpdateLineDisplayName: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case MEMBER.IS_PROCESS:
      return Object.assign({}, state, { isProcess: action.isProcess });

    case MEMBER.PROFILE_REGISTER:
      let data =
        action.data && action.data.data.length > 0 && action.data.data[0];
      return Object.assign({}, state, {
        isProcess: action.isProcess,
        isError: action.isError,
        errorMessage: action.errorMessage,
        profile: [
          Object.assign({}, state.profile[0], {
            first_name: data && data.firstName,
            last_name: data && data.lastName,
            gender: data && data.gender,
            is_registered: data && data.is_registered,
            date_of_birth: data && data.dateOfBirth,
            cfg_block_noti: (data && data.blockNoti) || false,
            cfg_block_birthday: (data && data.blockBirthday) || null,
            email: (data && data.email) || "",
            line_list: data && data.line_list,
            email_verified: data && data.email_verified,
          }),
        ],
        phoneNumber: action.phoneNumber || state.phoneNumber,
        memberBadgeCode: action.memberBadgeCode || state.memberBadgeCode,
      });

    case MEMBER.SET_USER_PERMISSION:
      return Object.assign({}, state, {
        profile: action.profile || state.profile,
        phoneNumber: action.phoneNumber || state.phoneNumber,
        memberBadgeCode: action.memberBadgeCode || state.memberBadgeCode,
      });

    case MEMBER.UPDATE_PROFILE_AVATAR:
      let profile = state.profile;
      profile[0].photo_uri = action.data;
      return Object.assign({}, state, {
        profile: profile || state.profile,
      });

    case MEMBER.MEMBER_ADDRESS:
      return Object.assign({}, state, {
        address: (action.data && action.data.data) || [],
        total_address: (action.data && action.data.total) || 0,
      });

    case MEMBER.REMOVE_LIFF:
      return Object.assign({}, state, {
        profile: [
          Object.assign({}, state.profile[0], {
            line_list: action.data || [],
          }),
        ],
      });

    case MEMBER.UPDATE_EMAIL:
      return Object.assign({}, state, {
        profile: [
          Object.assign({}, state.profile[0], {
            email: action.data,
            email_verified: true,
          }),
        ],
      });

    case MEMBER.SET_UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        updateProfileSuccess: action.data,
      });
    
    case MEMBER.UPDATE_LINE_DISPLAYNAME_LOADING:
      return Object.assign({}, state, {
        isLoadingUpdateLineDisplayName: action.data,
      });
    
    case MEMBER.UPDATE_CFG_BLOCK_NOTI:
      return Object.assign({}, state, {      
        profile: [
          Object.assign({}, state.profile[0], {
            ...state.profile[0],
            cfg_block_noti: action.data,
          }),
        ],
      });

    default:
      return state;
  }
};
