import axios from "./../axios_request";
import { PAPI } from "../../libs/_endpoint";
import { EMAIL } from "../action_const";
import { getCookie } from "../../libs/cookie";

const cooldown_time = 60;

export const OTPEmailRequest = (email, lang) => {
  return (dispatch) => {
    dispatch({
      type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
      data: Object.assign({ code: false }, { email }),
    });

    let _time = window.ps.getNowDate().getTime();
    cooldown(dispatch, _time); //set cooldown

    axios({
      method: "post",
      url: `${PAPI}/oauth2/OTPEmailRequest`,
      data: { email, lang },
    })
      .then(({ data }) => {
        if (data.result) {
          dispatch({
            type: EMAIL.OTP_EMAIL_REQUEST,
            data: Object.assign(data.data, { email, lang }),
          });
        } else {
          dispatch({
            type: EMAIL.OTP_EMAIL_REQUEST_FAILED,
            data: Object.assign(data.error, { email, lang }),
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EMAIL.OTP_EMAIL_REQUEST_FAILED,
          data: Object.assign(
            { code: "internal-error", message: error },
            { email, lang }
          ),
        });
      });
  };
};

export const OTPEmailVerify = (email, refCode, otp) => {
  return (dispatch) => {
    dispatch({
      type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
      data: Object.assign({ code: false }, { email }),
    });
    axios({
      method: "post",
      url: `${PAPI}/oauth2/OTPEmailVerify`,
      data: { email, refCode, otp },
    })
      .then(({ data }) => {
        if (data.result) {
          dispatch({
            type: EMAIL.OTP_EMAIL_VERIFY,
            data: Object.assign(data.data, { email }),
          });
        } else {
          dispatch({
            type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
            data: Object.assign(data.error, { email }),
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
          data: { error: "internal-error", message: error },
        });
      });
  };
};

function cooldown(dispatch, _lastLogin) {
  let cooldown = cooldown_time;
  let _time = parseInt(window.ps.getNowDate().getTime() / 1000);
  _lastLogin = parseInt(_lastLogin / 1000);

  cooldown = _lastLogin + cooldown > _time ? _lastLogin + cooldown - _time : 0;

  window.ps.process_cooldown_email && clearInterval(window.ps.process_cooldown_email);

  dispatch({ type: EMAIL.SET_RESEND_OTP_COOLDOWN, cooldown: cooldown });
  window.ps.process_cooldown_email = setInterval(() => {
    cooldown = cooldown - 1;
    dispatch({ type: EMAIL.SET_RESEND_OTP_COOLDOWN, cooldown: cooldown });
    if (cooldown < 0) clearInterval(window.ps.process_cooldown_email);
  }, 1000);
}

export const CheckEmailExist = (email) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/email/checkEmailExist`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { email },
    })
      .then(({ data }) => {
        let dataObject = data.data;
        if (dataObject.length > 0) {
          dispatch({
            type: EMAIL.EMAIL_EXIST,
            data: true,
          });
        } else {
          dispatch({
            type: EMAIL.EMAIL_EXIST,
            data: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: EMAIL.EMAIL_EXIST,
          data: null,
        });
      });
  };
};

export const setEmailExistNull = () => {
  return (dispatch) => {
    dispatch({ type: EMAIL.SET_EMAIL_EXIST_NULL });
  };
};

export const setTempMail = (tempEmail) => {
  return (dispatch) => {
    dispatch({ type: EMAIL.TEMP_MAIL, data: tempEmail });
  };
};

export const setResultFalse = () => {
  return (dispatch) => {
    dispatch({ type: EMAIL.SET_RESULT_FALSE });
  };
};

export const ResetError = () => {
  return (dispatch) => {
    dispatch({ type: EMAIL.ERROR_RESET });
  };
};

export const getConfigSettingEmail = (memberBadgeCode) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/email/getConfigSettingEmail`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { memberBadgeCode },
    })
      .then(({ data }) => {
        dispatch({
          type: EMAIL.SET_CONFIG_SETTING_EMAIL,
          summary: data.data.email_summary_noti,
          transaction: data.data.email_ts_noti,
        });
      })
      .catch((error) => {});
  };
};

export const updateEmailSummaryNoti = (value, memberBadgeCode) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/email/updateEmailSummaryNoti`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { value, memberBadgeCode },
    })
      .then(({ data }) => {
        dispatch({ type: EMAIL.UPDATE_EMAIL_SUMMARY, data: value });
      })
      .catch((error) => {});
  };
};

export const updateEmailTransactionNoti = (value, memberBadgeCode) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/email/updateEmailTransactionNoti`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { value, memberBadgeCode },
    })
      .then(({ data }) => {
        dispatch({ type: EMAIL.UPDATE_EMAIL_TRANSACTION, data: value });
      })
      .catch((error) => {});
  };
};

export const ResetCooldown = () => {
  return (dispatch) => {
    dispatch({ type: EMAIL.SET_RESEND_OTP_COOLDOWN, cooldown: 0 });
  };
}