import { MEMBER } from "../action_const";

let initState = {
  isLoading: true,
  isError: false,
  auth: false,
  register: false,
  ref: true,
  redirectPath: '/points'
}

export default (state = initState, action) => {
  switch (action.type) {
    // case MEMBER.MEMBER_LOGIN:
    //   return Object.assign({}, state, { auth: true })
    // case MEMBER.MEMBER_LOGOUT:
    //   return Object.assign({}, state, { auth: false })
    // case MEMBER.SET_MEMBER_REGISTER:
    //   return Object.assign({}, state, { register: true })
    case MEMBER.SET_MEMBER_REF_CODE:
      return Object.assign({}, state, { ref: false })
    case MEMBER.PROFILE_REGISTER:
      return Object.assign({}, state, {
        register: action.register || state.register
      })
    case MEMBER.SET_USER_PERMISSION:
      return Object.assign({}, state, action.data)
    case MEMBER.SET_REDIRECT_PATH:
      return Object.assign({}, state, { redirectPath: action.data })
    default:
      return state
  }
}

