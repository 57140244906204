module.exports = () => {
  let { REACT_APP_TERMS_URL, REACT_APP_POLICY_URL, REACT_APP_LANDING_PAGE_URL } = process.env

  return {
    title: 'pointspot.co | สะสมแต้ม',
    base_title: 'pointspot.co | ',
    metatag: {
      url: 'https://pointspot.co',
      type: 'article',
      title: 'pointspot.co | สะสมแต้ม',
      description: 'Pointspot - สะสมแต้ม ใช้งานง่ายผ่านเวปแอพฯ ใช้งานง่าย เพียงใช้เบอร์โทรศัพท์เท่านั้น และสามารถแลกแต้มเพื่อแลกของรางวัลมากมาย',
      image: 'https://firebasestorage.googleapis.com/v0/b/rp-membergo.appspot.com/o/images%2F81090f87%2FRPHR-Square-2.jpg?alt=media&token=d521f96d-809b-4d42-8127-2eeac5b31c05'
    },
    landingpage_link: REACT_APP_LANDING_PAGE_URL,
    terms_link: REACT_APP_TERMS_URL,
    privacy_link: REACT_APP_POLICY_URL
  }
}