import { WALLET } from "../action_const";

export default (state = {}, action) => {
  switch (action.type) {
    case WALLET.SET_WALLET_BALANCE:
      let data = action.payload.data
      if (!data || !data.data) return state;
      return Object.assign({}, state, { [data.data.businessCode]: data.data })
    default:
      return state
  }
}

