import { REFERENCECODE } from "../action_const";

let initState = {
  isLoading: true,
  isLoadingGamble: false,
  detail: [],
  review: [],
  gamble: {
    wheel: [],
    config: false,
    date: '',
    minimum_limit: '',
    luckyNum: '',
    result: '',
  },
  changePhone: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case REFERENCECODE.GET_REFFENENCE_DETAIL:
      return Object.assign({}, state, {
        detail: action.data || [],
        isLoading: false
      })

    case REFERENCECODE.UPDATE_REFFENENCE_DETAIL:
      if (state.detail[0]) {
        state.detail[0].activate_date = action.data
        state.detail[0].activated = action.data
      }
      return Object.assign({}, state, {
        detail: state.detail
      })

    case REFERENCECODE.GET_REFFENENCE_DETAIL_LOADING:
      return Object.assign({}, state, {
        isLoading: true
      })

    case REFERENCECODE.SET_BUSINESS_REVIEW:
      return Object.assign({}, state, {
        review: action.data,
      })

    case REFERENCECODE.GET_GAMBLE_LOADING:
      return Object.assign({}, state, {
        isLoadingGamble: true
      })

    case REFERENCECODE.GET_GAMBLE_DETAIL:
      return Object.assign({}, state, {
        isLoadingGamble: false,
        gamble: Object.assign({}, state.gamble, action.data)
      })

    case REFERENCECODE.GET_GAMBLE_RESULT:
      return Object.assign({}, state, {
        isLoadingGamble: false,
        gamble: Object.assign({}, state.gamble, {
          luckyNum: action.data && action.data.luckyNumber,
          result: action.data && action.data.result
        })
      })

    case REFERENCECODE.GET_GAMBLE_ERROR:
      return Object.assign({}, state, {
        isLoadingGamble: false,
        gamble: initState.gamble
      })

    case REFERENCECODE.GAMBLE_DRAW_ERROR:
      return Object.assign({}, state, {
        isLoadingGamble: false,
        gamble: Object.assign({}, state.gamble, {
          luckyNum: '',
          result: ''
        })
      })

    case REFERENCECODE.SET_CHANGE_PHONE_DATA:
      return Object.assign({}, state, {
        changePhone: action.data
      })  

    default:
      return state
  }
}

