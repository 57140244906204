
module.exports = () => {
  const { REACT_APP_FB_API_KEY, REACT_APP_FB_PROJECT_ID, REACT_APP_FB_SENDER_ID, REACT_APP_FCM_PUBLICKEY, REACT_APP_FB_APP_ID } = process.env
  return {
    apiKey: REACT_APP_FB_API_KEY,
    authDomain: `${REACT_APP_FB_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${REACT_APP_FB_PROJECT_ID}.firebaseio.com`,
    projectId: `${REACT_APP_FB_PROJECT_ID}`,
    storageBucket: `${REACT_APP_FB_PROJECT_ID}.appspot.com`,
    messagingSenderId: REACT_APP_FB_SENDER_ID,
    fcmPublicKey: REACT_APP_FCM_PUBLICKEY,
    appId: REACT_APP_FB_APP_ID
  }
}