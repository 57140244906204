import axios from "../axios_request";
import { POINT } from "../action_const";
import { PAPI } from "../../libs/_endpoint";
import { getCookie } from "../../libs/cookie";
import { ERROR, DEBUG } from "../../libs/utils";
import { doc, onSnapshot, collection, updateDoc } from "firebase/firestore";
import { fbFirestore } from "./../../libs/Firebase";

export const createPointsRequest = (business_code, data) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/business/${business_code}/requestPoints/createPointsRequest`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: data,
    })
      .then(({ data }) => {
        dispatch({ type: POINT.SEND_REQUEST, data: data.data });
        dispatch({ type: POINT.SEND_SUCCESS });
      })
      .catch((err) => {
        const error_products_archive = err.response.data?.data?.products_archive || []
        if (error_products_archive && error_products_archive.length > 0) { 
          const productsArchive = error_products_archive;
          dispatch({
            type: POINT.SEND_UNSUCCESS, data: {
              error_products_archive: {
                product: productsArchive,
                error_code: err.response.data?.error.code,
                error_message: err.response.data?.error.message
              }
            }
          });
        }
        dispatch({ type: POINT.SEND_UNSUCCESS });
      });
  };
};

export const createRequestNoproduct = (business_code, data) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/business/${business_code}/requestPoints/createRequestNoProduct`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: data,
    })
      .then(({ data }) => {
        dispatch({ type: POINT.SEND_REQUEST, data: data.data });
        dispatch({ type: POINT.SEND_SUCCESS });
      })
      .catch((err) => {
        dispatch({ type: POINT.SEND_UNSUCCESS });
      });
  };
};

export const editPointsRequest = (business_code, data) => {
  return (dispatch) => {
    axios({
      method: "put",
      url: `${PAPI}/az/business/${business_code}/requestPoints/editPointRequest`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: data,
    }).then(({ data }) => {
      let dataArray = Object.values(data.data[0]);
      dispatch({ type: POINT.GET_REQUEST, data: dataArray, count: 0 });
      dispatch({ type: POINT.EDIT_SUCCESS });
    }).catch((err) => {
      const error_products_archive = err.response.data?.data?.products_archive || []
      if (error_products_archive && error_products_archive.length > 0) { 
        const productsArchive = error_products_archive;
        dispatch({
          type: POINT.EDIT_UNSUCCESS, data: {
            error_products_archive: {
              product: productsArchive,
              error_code: err.response.data?.error.code,
              error_message: err.response.data?.error.message
            }
          }
        });
      }
      dispatch({ type: POINT.EDIT_UNSUCCESS });
    })
  }
}

export const editRequestNoproduct = (business_code, data) => {
  return (dispatch) => {
    axios({
      method: "put",
      url: `${PAPI}/az/business/${business_code}/requestPoints/editRequestNoProduct`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: data,
    }).then(({ data }) => {
      let dataArray = Object.values(data.data[0]);
      dispatch({ type: POINT.GET_REQUEST, data: dataArray, count: 0 });
      dispatch({ type: POINT.EDIT_SUCCESS });
    }).catch((err) => {
      dispatch({ type: POINT.EDIT_UNSUCCESS });
    })
  }
}

export const RemoveToast = () => {
  return (dispatch) => {
    dispatch({ type: POINT.SET_NULL });
  };
};

export const getCollectPoint = (business_code, data, count) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/business/${business_code}/requestPoints/getPointRequest`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: data,
    })
      .then(({ data }) => {
        let dataArray = Object.values(data.data[0]);
        if (data) {
          dispatch({ type: POINT.GET_REQUEST, data: dataArray, count: count });
          dispatch({ type: POINT.SET_LOADING_INFO, loading: false });
        } else {
          dispatch({ type: POINT.SET_LOADING_INFO, loading: true });
        }
      })
      .catch((err) => {
        dispatch({ type: POINT.SET_LOADING_INFO, loading: false });
      });
  };
};

export const setDefaultLoadingInfo = () => {
  return (dispatch) => {
    dispatch({ type: POINT.SET_LOADING_INFO, loading: true });
  };
};

export const onSnapShotReject = (member_badge_code, callback) => {
  return (dispatch) => {
    let bizArray = [];

    const newRejectRef = collection(
      fbFirestore,
      "profile",
      `${member_badge_code}`,
      "new_reject"
    );
    onSnapshot(
      newRejectRef,
      (snapshot) => {
        if (snapshot) {
          snapshot.docChanges().forEach((change) => {
            let biz =
              change.doc._document.data.value.mapValue.fields.business_code
                .stringValue;
            if (change.doc.data().reject > 0) {
              bizArray.push(biz);
            }
          });
          dispatch({ type: POINT.NEW_REJECT, data: bizArray });
        }
      },
      (error) => ERROR("onSnapshot path new_reject", error)
    );

    document[member_badge_code] = doc;
  };
};

export const removeNewReject = (member_badge_code, business_code) => {
  if (!member_badge_code) return null;

  return (dispatch) => {
    DEBUG(
      "removeNewReject",
      member_badge_code,
      Boolean(business_code) ? business_code : "all"
    );
    const date = new Date();
    const newRejectRef = doc(
      fbFirestore,
      "profile",
      `${member_badge_code}`,
      "new_reject",
      business_code
    );

    // if (!newRejectRef.exist) return null;

    updateDoc(newRejectRef, {
      reject: 0,
      updateDate: date,
    });
    return dispatch({ type: POINT.REMOVE_NEW_REJECT, data: business_code });
  };
};

export const clearInfo = () => {
  return (dispatch) => {
    dispatch({ type: POINT.CLEAR_INFO });
  };
};
