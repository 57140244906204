import styled, { css } from "styled-components";

export const GenderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
`;

export const Gender = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease;
  ${(props) =>
    props.select
      ? css`
          border: 2px solid ${props.colorSelect};
        `
      : css`
          border: 1px solid #cdd4db;
        `}
`;

export const ToastBar = styled.div`
  width: 100%;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const EditProfileMainContainer = styled.div`
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
`;

export const IconBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: rgba(81, 99, 115, 0.05);
  border-radius: 8px;
`;

export const ProfileTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  align-items: center;
  margin-bottom: 10px;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
`;

export const ProfileBox = styled.div`
  display: flex;
  align-items: center;
`;
