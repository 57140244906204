import axios from './../axios_request'
import { PAPI } from './../../libs/_endpoint'
import { REWARDS, MEMBER } from '../action_const'
import { getCookie } from '../../libs/cookie'

export const getRewardsList = (businessCode) => {
  return dispatch => {
    dispatch({ type: REWARDS.IS_LOADING })
    axios({
      method: 'get',
      url: `${PAPI}/business/${businessCode}/reward`,
      headers: {
        'Authorization': `${getCookie('__a') ? `Bearer ${getCookie('__a')}` : ''}`,
        'Client': getCookie('__cid') ? getCookie('__cid') : ''
      }
    }).then(res => {
      if (!res.data.result) return null
      dispatch({
        type: REWARDS.SET_REWARDS_LIST,
        data: res.data.data
      })
    }).catch(error => {
      dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: { isError: true, isLoading: true }
      })
    })
  }
}

export const getRewardsCoupon = (businessCode) => {
  return dispatch => {
    dispatch({ type: REWARDS.IS_LOADING_COUPON })
    axios({
      method: 'get',
      url: `${PAPI}/az/business/${businessCode}/coupons2`,
      headers: {
        'Authorization': `Bearer ${getCookie('__a')}`,
        'Client': getCookie('__cid')
      }
    }).then(res => {
      if (!res.data.result) return Promise.reject()
      dispatch({
        type: REWARDS.SET_REWARDS_COUPON_LIST,
        data: res.data.data
      })
    }).catch(error => {
      dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: {
          isError: true,
          isLoading: true
        }
      })
    })
  }
}

export const getRedemptionActive = ({ business_code, ecard_id }, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${PAPI}/az/business/${business_code}/coupons2`,
      headers: { 'Authorization': `Bearer ${getCookie('__a')}`, 'Client': getCookie('__cid') },
      data: { ecard_id: ecard_id }
    }).then(({ data }) => {
      if (data.result && (typeof callback === 'function'))
        return callback(null, data.data)
      else
        return Promise.reject(data.error || 'result error')
    }).catch(error => {
      if (typeof callback === 'function')
        return callback(error)
    })
  }
}

export const clearRewardsPage = () => {
  return { type: REWARDS.CLEAR_REWARD_COUPON }
}

export const setRewardsPersistedState = (rewards) => {
  return dispatch => {
    dispatch({ type: REWARDS.SET_REWARDS_PERSISTED_STATE, data: rewards })
  }
}

export const ActivatedByPinCode = async (
  business_code,
  trans_id,
  type,
  data,
  callback
) => {
  let isFunc = typeof callback === "function";

  return axios({
    method: "put",
    url: `${PAPI}/az/business/${business_code}/activate/${trans_id}`,
    headers: {
      Authorization: `Bearer ${getCookie("__a")}`,
      Client: getCookie("__cid"),
    },
    data: { type, data },
  })
    .then((data) => {
      return isFunc && callback(null, data.data);
    })
    .catch((err) => {
      return isFunc && callback(err);
    });
};

export const ActivatedMultipleCoupon = async (business_code, body, callback) => {
  let isFunc = typeof callback === 'function'
  return axios({
    method: 'put',
    url: `${PAPI}/az/business/${business_code}/activates`,
    headers: {
      'Authorization': `Bearer ${getCookie('__a')}`,
      'Client': getCookie('__cid')
    },
    data: body
  }).then((data) => {
    return isFunc && callback(null, data.data)
  }).catch((err) => {
    return isFunc && callback(err)
  })
}

export const getGiveawayCoupons = (business_code, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    dispatch({ type: REWARDS.IS_LOADING_GIVEAWAY })
    axios({
      method: 'get',
      url: `${PAPI}/business/${business_code}/giveaway`,
      headers: {
        'Authorization': `Bearer ${getCookie('__a')}`,
        'Client': getCookie('__cid')
      }
    }).then(({ data }) => {
      if (data.result) {
        isFunc && callback(null, data.data)
        return dispatch({ type: REWARDS.GET_GIVEAWAY_COUPON, data: data.data })
      } return Promise.reject(data.error || 'Result error')
    }).catch((err) => {
      console.error(err)
      isFunc && callback(err)
      return dispatch({ type: REWARDS.GET_GIVEAWAY_COUPON, data: [] })
    })
  }
}

export const setIsActivated = (isActivated) => {
  return dispatch => {
    dispatch({ type: REWARDS.SET_IS_ACTIVATED, data: isActivated })
  }
}