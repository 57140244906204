const TagManager = require("./../libs/TagManager");

module.exports.TagManager = TagManager;

module.exports.MEMBER = {
  MEMBER_LOGIN: "MEMBER_LOGIN",
  MEMBER_LOGOUT: "MEMBER_LOGOUT",
  SET_MEMBER_REGISTER: "SET_MEMBER_REGISTER",
  SET_MEMBER_REF_CODE: "SET_MEMBER_REF_CODE",

  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_MEMBER_BADGE_CODE: "SET_MEMBER_BADGE_CODE",
  SET_MEMBER_PROFILE: "SET_MEMBER_PROFILE",
  SET_MEMBER_PROFILE_INFO: "SET_MEMBER_PROFILE_INFO",
  UPDATE_MEMBER_PROFILE: "UPDATE_MEMBER_PROFILE",
  SET_MEMBER_MOBILE_NUMBER: "SET_MEMBER_MOBILE_NUMBER",

  SET_USER_PERMISSION: "SET_USER_PERMISSION",
  SET_REDIRECT_PATH: "SET_REDIRECT_PATH",

  IS_PROCESS: "MEMBER_IS_PROCESS",
  PROFILE_REGISTER: "MEMBER_PROFILE_REGISTER",

  START_PROCESS: "START_PROCESS",
  UPDATE_MEMBER_PROFILE_2: "UPDATE_MEMBER_PROFILE_2",
  UPDATE_PROFILE_AVATAR: "UPDATE_PROFILE_AVATAR",
  MEMBER_ADDRESS: "MEMBER_ADDRESS",
  REMOVE_LIFF: "REMOVE_LIFF",

  UPDATE_EMAIL: 'UPDATE_EMAIL',
  SET_UPDATE_PROFILE_SUCCESS: 'SET_UPDATE_PROFILE_SUCCESS',
  UPDATE_LINE_DISPLAYNAME_LOADING: 'UPDATE_LINE_DISPLAYNAME_LOADING',
  UPDATE_CFG_BLOCK_NOTI: 'UPDATE_CFG_BLOCK_NOTI',
}

//WALLET
module.exports.WALLET = {
  SET_WALLET_BALANCE: "SET_WALLET_BALANCE",
};

//BUSINESS
module.exports.BUSINESS = {
  SET_BUSINESS_LIST: 'SET_BUSINESS_LIST',
  BUSINESS_IS_PROCESS: 'BUSINESS_IS_PROCESS',
  ADD_BUSINESS_LIST: 'ADD_BUSINESS_LIST',
  SET_BUSINESS_QUEUE: 'SET_BUSINESS_QUEUE',
  APPEND_BUSINESS_LIST: 'APPEND_BUSINESS_LIST',
  START_LOADMORE_BUSINESS_LIST: 'START_LOADMORE_BUSINESS_LIST',
  SUBSCRIBE_BUSINESS: 'SUBSCRIBE_BUSINESS',
  SUBSCRIBE_BUSINESS_IS_START: 'SUBSCRIBE_BUSINESS_IS_START',
  UNSUBSCRIBE_BUSINESS: 'UNSUBSCRIBE_BUSINESS',
  UNSUBSCRIBE_BUSINESS_IS_START: 'UNSUBSCRIBE_BUSINESS_IS_START',
  FOLLOW_BUSINESS_IS_START: 'FOLLOW_BUSINESS_IS_START',
  FOLLOW_BUSINESS: 'FOLLOW_BUSINESS',
  TOGGLE_NOTIFICATION_BUSINESS: 'TOGGLE_NOTIFICATION_BUSINESS',
  SET_BUSINESS_CODE_FROM_SUBSCRIBE: 'SET_BUSINESS_CODE_FROM_SUBSCRIBE',
  SET_CURRENT_MEMBER_CARD: 'SET_CURRENT_MEMBER_CARD',
  SET_MEMBER_HAS_CARD: 'SET_MEMBER_HAS_CARD',
};

module.exports.REWARDS = {
  SET_REWARDS_LIST: 'SET_REWARDS_LIST',
  SET_REWARDS_PERSISTED_STATE: 'SET_REWARDS_PERSISTED_STATE',
  APPEND_REWARDS_LIST: 'APPEND_REWARDS_LIST',
  START_REWARD_LOADMORE: 'START_REWARD_LOADMORE',
  SET_REWARDS_COUPON_LIST: 'SET_REWARDS_COUPON_LIST',
  CLEAR_REWARD_COUPON: 'CLEAR_REWARD_COUPON',
  IS_LOADING: 'REWARD_IS_LOADING',
  IS_LOADING_COUPON: 'IS_LOADING_COUPON',
  IS_LOADING_GIVEAWAY: 'IS_LOADING_GIVEAWAY',
  GET_GIVEAWAY_COUPON: 'GET_GIVEAWAY_COUPON',
  SET_IS_ACTIVATED: 'SET_IS_ACTIVATED',
}

//HISTORY
module.exports.HISTORY = {
  SET_HISTORY_LIST: 'SET_HISTORY_LIST',
  SET_HISTORY_LIST_PERSISTED_STATE: 'SET_HISTORY_LIST_PERSISTED_STATE',
  SET_HISTORY_RECENTLY: 'SET_HISTORY_RECENTLY',
  SET_HISTORY_RECENTLY_PERSISTED_STATE: 'SET_HISTORY_RECENTLY_PERSISTED_STATE',
  APPEND_HISTORY_LIST: 'APPEND_HISTORY_LIST',
  START_SET_HISTORY_LIST: 'START_SET_HISTORY_LIST',
  CLEAR_HISTORY_LIST: 'CLEAR_HISTORY_LIST',
  CLEAR_HISTORY_RECENT_LIST: 'CLEAR_HISTORY_RECENT_LIST',
  START_GET_HISTORY_RECENTLY: 'START_GET_HISTORY_RECENTLY',
}

//REFERENCE
module.exports.REFERENCECODE = {
  GET_REFFENENCE_DETAIL: 'REFERENCECODE_GET_REFFENENCE_DETAIL',
  GET_REFFENENCE_DETAIL_LOADING: 'GET_REFFENENCE_DETAIL_LOADING',
  SET_BUSINESS_REVIEW: 'REFERENCECODE_SET_BUSINESS_REVIEW',
  GET_GAMBLE_LOADING: 'GET_GAMBLE_LOADING',
  GET_GAMBLE_DETAIL: 'GET_GAMBLE_DETAIL',
  GET_GAMBLE_RESULT: 'GET_GAMBLE_RESULT',
  GET_GAMBLE_ERROR: 'GET_GAMBLE_ERROR',
  GAMBLE_DRAW_ERROR: 'GAMBLE_DRAW_ERROR',
  UPDATE_REFFENENCE_DETAIL: 'UPDATE_REFFENENCE_DETAIL',
  SET_CHANGE_PHONE_DATA: 'SET_CHANGE_PHONE_DATA'
}

module.exports.PAGE = {
  SET_PAGE_NAV: "SET_PAGE_NAV",
  HIDE_DROPDOWN: "HIDE_DROPDOWN",
  SHOW_DROPDOWN: "SHOW_DROPDOWN",
};

module.exports.TRANSFERPOINT = {
  START_TRANSFER_TO: "START_TRANSFER_TO",
  END_TRANSFER_TO: "END_TRANSFER_TO",
  TRANSFER_HAVE_ERROR: "TRANSFER_HAVE_ERROR",
};

module.exports.LANGUAGE = {
  SELECT_LANGUAGE: "SELECT_LANGUAGE",
};

module.exports.MYCOUPONS = {
  CLEAR_COUPONS_STATE: 'CLEAR_COUPONS_STATE',
  GET_COUPONS_LIST: 'GET_COUPONS_LIST',
  LOADING_COUPONS_LIST: 'LOADING_COUPONS_LIST',
  NEW_MY_COUPON: 'NEW_MY_COUPON',
  REMOVE_NEW_MY_COUPON: 'REMOVE_NEW_MY_COUPON',
}

module.exports.LOGIN = {
  IS_PROCESS: "LOGIN_IS_PROCESS",
  IS_RESEND_OTP: "LOGIN_IS_RESEND_OTP",
  PHONE_REQUEST_OTP: "LOGIN_PHONE_REQUEST_OTP",
  VERIFY_OTP: "LOGIN_VERIFY_OTP",
  SET_RESEND_OTP_COOLDOWN: "LOGIN_SET_RESEND_OTP_COOLDOWN",
  CANCEL_OTP: "CANCEL_OTP",
  SYNCTIME: "SYNCTIME",
  SET_LINE_USER_INFO: "SET_LINE_USER_INFO",
  SIGN_IN_FROM_LINE: "SIGN_IN_FROM_LINE",
  SET_TODO_SEND_OTP_VIA_EMAIL: "SET_TODO_SEND_OTP_VIA_EMAIL",
  SET_IS_EMAIL_VERIFIED: "SET_IS_EMAIL_VERIFIED",
  SET_PROFILE_FOR_LOGIN: "SET_PROFILE_FOR_LOGIN",
};

module.exports.VERIFYOTP = {
  IS_PROCESS: 'VERIFY_IS_PROCESS',
  REQUEST_OTP: 'REQUEST_OTP',
  REQUEST_OTP_ERROR: 'REQUEST_OTP_ERROR',
  VERIFY_OTP: 'VERIFY_OTP',
  SET_COOLDOWN_OTP: 'SET_COOLDOWN_OTP',
}

module.exports.CONDITION = {
  PROCESS_CONDITION: "PROCESS_CONDITION",
  GET_CONDITION: "GET_CONDITION",
  ERROR_CONDITION: "ERROR_CONDITION",
  GET_MEMBER_LIST_BY_REFERER: "GET_MEMBER_LIST_BY_REFERER",
};

module.exports.PRODUCT = {
  CATEGORY_LIST: 'CATEGORY_LIST',
  PRODUCT_LIST: 'PRODUCT_LIST',
  ALL_PRODUCT_LIST: 'ALL_PRODUCT_LIST',
  TEMP_PRODUCT: 'TEMP_PRODUCT',
}

module.exports.POINT = {
  SEND_REQUEST: "SEND_REQUEST",
  SEND_SUCCESS: "POINT.SEND_SUCCESS",
  SEND_UNSUCCESS: "SEND_UNSUCCESS",
  EDIT_SUCCESS: "EDIT_SUCCESS",
  EDIT_UNSUCCESS: "EDIT_UNSUCCESS",
  SET_NULL: "SET_NULL",
  GET_REQUEST: "GET_REQUEST",
  SET_LOADING_INFO: "SET_LOADING_INFO",
  SET_HAS_MORE: "SET_HAS_MORE",
  NEW_REJECT: "NEW_REJECT",
  REMOVE_NEW_REJECT: "REMOVE_NEW_REJECT",
  CLEAR_INFO: "CLEAR_INFO",
};

module.exports.EMAIL = {
  OTP_EMAIL_REQUEST: "OTP_EMAIL_REQUEST",
  OTP_EMAIL_REQUEST_FAILED: "OTP_EMAIL_REQUEST_FAILED",
  SET_RESEND_OTP_COOLDOWN: "SET_RESEND_OTP_COOLDOWN",
  OTP_EMAIL_VERIFY: "OTP_EMAIL_VERIFY",
  OTP_EMAIL_VERIFY_FAILED: "OTP_EMAIL_VERIFY_FAILED",
  EMAIL_EXIST: "EMAIL_EXIST",
  SET_EMAIL_EXIST_NULL: "SET_EMAIL_EXIST_NULL",
  TEMP_MAIL: "TEMP_MAIL",
  SET_RESULT_FALSE: "SET_RESULT_FALSE",
  ERROR_RESET: "ERROR_RESET",
  SET_CONFIG_SETTING_EMAIL: "SET_CONFIG_SETTING_EMAIL",
  UPDATE_EMAIL_SUMMARY: "UPDATE_EMAIL_SUMMARY",
  UPDATE_EMAIL_TRANSACTION: "UPDATE_EMAIL_TRANSACTION",
};

module.exports.TOTP = {
  GET_TOKEN: "GET_TOKEN",
  GET_TIMEREMAIN: "GET_TIMEREMAIN",
  TIMEOUT: "TIMEOUT",
};

module.exports.IP = {
  SET_INFO_FROM_IP: "SET_INFO_FROM_IP",
  SET_IS_LOADING: "SET_IS_LOADING",
};


module.exports.CUSTOM_FORM = {
  GET_CUSTOM_FORM_TITLE: 'GET_CUSTOM_FORM_TITLE',
  SET_FORM_INFO: 'SET_FORM_INFO',
  LOADING_FORM: 'LOADING_FORM',
  LOADING_FORM_INFO: 'LOADING_FORM_INFO',
}