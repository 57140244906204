import React, { useEffect, useState } from "react";
import { Col, Input, Modal } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  DataHeader,
  DataTitle,
  LanguageSection,
  MainContainer,
  SaveButton,
} from "./PopupRequestUserInformation.style";
import DatePicker from "../DatePicker";
import {
  Gender,
  GenderBox,
} from "../../pages/EditProfile/EditProfileForm.style";
import { Title2 } from "../Font";
import {
  ResetUpdateProfileSuccess,
  updateUserProfile,
} from "../../redux/actions/action_profile";
import { toast } from "react-toastify";
import "./PopupRequestUserInformation.css";
import {
  LanguageBox,
  LanguageContainer,
} from "../../pages/Register/Register.style";
import { selectLang } from "../../redux/actions/action_language";
import { getCookie } from "../../libs/cookie";

function PopupRequestUserInformation() {
  const { dictionary } = useSelector((state) => state.language);
  let { profile, updateProfileSuccess } = useSelector((state) => state.profile);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [showField, setShowField] = useState({
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    gender: false,
  });

  let isCenterModal = window.innerWidth > 480;

  const dispatch = useDispatch();

  useEffect(() => {
    if (profile && profile[0]) {
      setShowField({
        firstName: !profile[0].first_name || profile[0].first_name === "",
        lastName: !profile[0].last_name || profile[0].last_name === "",
        dateOfBirth: !profile[0].date_of_birth,
        gender: !profile[0].gender,
      });

      if (
        profile[0].is_registered &&
        (!profile[0].first_name ||
          profile[0].first_name === "" ||
          !profile[0].last_name ||
          profile[0].last_name === "" ||
          !profile[0].date_of_birth ||
          !profile[0].gender)
      ) {
        setIsOpenModal(true);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (updateProfileSuccess === true && isToast) {
      toastShow("success", dictionary.save_data_successfully);
    } else if (updateProfileSuccess === false && isToast) {
      toastShow(
        "error",
        dictionary.save_data_unsuccessfully,
        dictionary.please_try_again
      );
      dispatch(ResetUpdateProfileSuccess());
    }
    setIsToast(false);
    // eslint-disable-next-line
  }, [updateProfileSuccess, dictionary, dispatch]);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    }
  }

  function handleChangeDate(date) {
    let _date =
      date.month && date.year
        ? `${date.date || "01"}-${date.month}-${date.year}`
        : "";
    setDateOfBirth(_date);
  }

  function handleClickGender(gender) {
    setGender(gender);
  }

  function validateField() {
    let result = true;

    result =
      result &&
      ((showField.firstName && firstName?.trim() !== "") ||
        !showField.firstName) &&
      ((showField.lastName && lastName?.trim() !== "") ||
        !showField.lastName) &&
      ((showField.dateOfBirth && dateOfBirth !== "") ||
        !showField.dateOfBirth) &&
      ((showField.gender && gender !== "") || !showField.gender);

    return !result;
  }

  function saveData() {
    setIsOpenModal(false);
    setIsToast(true);
    let userProfile = {
      firstName: showField.firstName ? firstName : profile[0].first_name,
      lastName: showField.lastName ? lastName : profile[0].last_name,
      dateOfBirth: showField.dateOfBirth
        ? dateOfBirth
        : profile[0].date_of_birth,
      gender: showField.gender ? gender : profile[0].gender,
      email: profile[0].email,
      blockNoti: profile[0].cfg_block_noti,
      blockBirthday: profile[0].cfg_block_birthday,
      email_verified: profile[0].email_verified,
    };
    dispatch(updateUserProfile(userProfile));
  }

  const handleModalEntered = (node, isAppearing) => {
    if (isAppearing) {
      const modalContent = node.querySelector(
        ".modal-bottom-information > .modal-content"
      );
      modalContent.classList.add("modal-enter");
      void modalContent.offsetHeight;
      modalContent.classList.add("modal-enter-active");
    }
  };

  const GenderData = [
    {
      id: "male",
      name: dictionary.male,
      color: "#418dd0",
    },
    {
      id: "female",
      name: dictionary.female,
      color: "#F2858E",
    },
    {
      id: "unspecified",
      name: dictionary.unspecified,
      color: "#000000",
    },
  ];

  let langCurrent = getCookie("__lang") || "th";

  const languageOption = [
    { shortLang: "en", description: "Eng" },
    { shortLang: "th", description: "ไทย" },
  ];

  return (
    <div>
      <Modal
        className={
          isCenterModal ? "modal-information" : "modal-bottom-information"
        }
        centered={isCenterModal}
        fade={isCenterModal}
        isOpen={isOpenModal}
        scrollable
        modalTransition={{
          onEntered: isCenterModal ? null : handleModalEntered,
          timeout: 300,
        }}
      >
        <MainContainer>
          <LanguageSection>
            <LanguageContainer>
              {languageOption.map((val, key) => {
                let _selectLang = val.shortLang === langCurrent;
                return (
                  <LanguageBox
                    pointer={!_selectLang}
                    selectLang={_selectLang}
                    key={`${key}_${val.shortLang}`}
                    onClick={() => dispatch(selectLang(val.shortLang))}
                  >
                    <Title2 bold center>
                      {val.description.toLocaleUpperCase()}
                    </Title2>
                  </LanguageBox>
                );
              })}
            </LanguageContainer>
          </LanguageSection>

          <DataHeader>{dictionary.please_enter_information}</DataHeader>

          {showField.firstName && (
            <div>
              <DataTitle>
                {dictionary.first_name}
                <div className="text-danger pr-2">*</div>
              </DataTitle>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                className="form-control-xs form"
                value={firstName}
                onChange={handleChange}
              />
            </div>
          )}

          {showField.lastName && (
            <div>
              <DataTitle>
                {dictionary.last_name}
                <div className="text-danger pr-2">*</div>
              </DataTitle>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                className="form-control-xs form"
                value={lastName}
                onChange={handleChange}
              />
            </div>
          )}

          {showField.dateOfBirth && (
            <div>
              <DataTitle>
                {dictionary.birthday_month}
                <div className="text-danger pr-2">*</div>
              </DataTitle>
              <DatePicker
                isShow={["month", "year"]}
                dictionary={dictionary}
                selected={dateOfBirth}
                onChange={handleChangeDate}
              />
            </div>
          )}

          {showField.gender && (
            <div>
              <DataTitle>
                {dictionary.gender}
                <div className="text-danger pr-2">*</div>
              </DataTitle>
              <GenderBox>
                {GenderData &&
                  GenderData.map((val, key) => {
                    let selectedGender = gender === val.id;

                    return (
                      <Col xs={4} key={`${val.id}_${key}`} className="px-1">
                        <Gender
                          select={selectedGender}
                          colorSelect={val.color}
                          onClick={() => handleClickGender(val.id)}
                        >
                          <Title2
                            bold
                            disabled={!selectedGender}
                            style={{ color: selectedGender ? val.color : null }}
                          >
                            {" "}
                            {val.name}{" "}
                          </Title2>
                        </Gender>
                      </Col>
                    );
                  })}
              </GenderBox>
            </div>
          )}

          <SaveButton onClick={saveData} disabled={validateField()}>
            {dictionary.save}
          </SaveButton>
        </MainContainer>
      </Modal>
    </div>
  );
}
export default PopupRequestUserInformation;

export function toastShow(type, message1, message2) {
  function handleRefresh() {
    window.location.reload();
  }

  toast[type](
    <div className="toast-bar" onClick={handleRefresh}>
      <Title2
        bold
        style={{
          width: "100%",
          textAlign: "center",
          color: type === "error" ? "#e20000" : "white",
        }}
      >
        {type === "error" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              columnGap: "10px",
            }}
          >
            <div>{message1}</div>
            <div style={{ color: "#5081FF", textDecoration: "underline" }}>
              {message2}
            </div>
          </div>
        ) : (
          message1
        )}
      </Title2>
    </div>,
    { autoClose: type === "error" ? false : true }
  );
}
