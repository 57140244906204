import moment from 'moment'
import { getCookie } from './cookie'

export const formatDate = (date) => {
  return moment(date).format('DD-MM-YYYY HH:mm')
}
export const formatDateFull = (date) => {
  return moment(date).format('D MMMM YYYY')
}
export const formatDateDB = (date) => {
  return moment(date).format('DD-MM-YYYY')
}
export const formatTime = (time) => {
  return moment(time, 'HH:mm:ss').format('HH:mm')
}
export const toMinute = (seconds) => {
  seconds = parseInt(seconds, 10)
  if (isNaN(seconds)) return false
  let divide = 60
  let temp = calculate(seconds, divide, [])
  return ` ${temp[0] ? `${temp[0]} min` : ""}  ${temp[1]} sec`;
}

function calculate(value, divide, resultArr) {
  if (divide < 1) return resultArr
  let result = Math.floor(value / divide)
  let next = value % divide
  resultArr.push(result)
  return calculate(next, divide / 60, resultArr)
}

export const getNowDate = (sync_ms = window.ps.synctime) => {
  return new Date(new Date().getTime() - sync_ms)
}

function countUpperCaseY(str) {
  return (str.match(/Y/g) || []).length;
}

export function formatDateCustom(date, format = "DD MMM YYYY") {
  let locale = getCookie("__lang");

  let countY = countUpperCaseY(format);
  
  let m = moment(date);

  let formatDate = m.format(format);

  if (locale === "th") {
    const firstYPosition = format.indexOf("Y");
    const lastYPosition = format.lastIndexOf("Y");

    const beforeY = format.substring(0, firstYPosition).trim();
    const afterY = format.slice(lastYPosition + 1).trim();

    if (beforeY.length > 0) {
      formatDate = m.format(beforeY);
    }

    if (countY === 2) {
      formatDate =
        formatDate + " " + (m.year() + 543).toString().substring(2, 4);
    } else if (countY === 4) {
      formatDate = formatDate + " " + (m.year() + 543).toString();
    }

    if (afterY.length > 0) {
      formatDate = formatDate + " " + m.format(afterY);
    }
  }

  return formatDate;
}