import { CONDITION } from '../action_const'

let initState = {
  isLoading: false,
  condition: [],
  invitee_list: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case CONDITION.PROCESS_CONDITION:
      return Object.assign({}, state, { isLoading: true })
    case CONDITION.GET_CONDITION:
      return Object.assign({}, state, { isLoading: false, condition: action.data || [] })
    case CONDITION.ERROR_CONDITION:
      return Object.assign({}, state, { isLoading: false, condition: state.condition || [] })
    case CONDITION.GET_MEMBER_LIST_BY_REFERER:
      return Object.assign({}, state, { isLoading: false, invitee_list: action.data || [] })
    default:
      return state
  }
}