import styled, { css } from "styled-components";

export const ImgLogo = styled.img`
  width: 80%;
  max-width: 500px;
`;

export const HeadBox = styled.div`
  height: 15%;
  max-height: 250px;
  width: 100%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #516373;
  border-radius: 0px 0px 30px 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const ConditionBox = styled.div`
  width: 100%;
  margin-bottom: 8px;
  min-width: 200px;
  padding: 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GenderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Gender = styled.div`
  cursor: pointer;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease;
  ${(props) =>
    props.select
      ? css`
          border: 2px solid ${props.colorSelect};
        `
      : css`
          border: 1px solid #cdd4db;
        `}
`;

export const LanguageContainer = styled.div`
  display: flex;
  border: 1px solid #e9ecef;
  border-radius: 8px;

  overflow: hidden;
`;

export const LanguageBox = styled.div`
  transition: all 0.2s linear;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;
  cursor: ${(props) => props.pointer && "pointer"};
  ${(props) =>
    props.selectLang
      ? css`
          opacity: 1;
          background-color: #e9ecef;
        `
      : css`
          opacity: 0.4;
        `};
`;

export const SpanWhite = styled.span`
  opacity: 0;
`;

export const RefererForm = styled.div`
  width: 100%;
  background: #ffffff;
  padding: ${(props) => (props.isPage ? "20px 0px" : "10px 0px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CreateText = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #516373;

  @media (max-width: 400px) {
    max-width: 200px;
  }
`;

export const Space = styled.div`
  height: 25px;
`;

export const TextHeader = styled.div`
  color: white;
  font-size: 36px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const CreateButton = styled.button`
  color: ${(props) => (props.disabled ? "#516373" : "white")};
  background-color: ${(props) => (props.disabled ? "#DDE3ED" : "#00C851")};
  min-height: 40px;
  height: auto;
  border-radius: 8px;
  border: none;
  width: 100%;
  padding: 5px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const TextError = styled.div`
  color: #ff892e;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
`;

export const IconBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: rgba(81, 99, 115, 0.05);
  border-radius: 8px;
`;
