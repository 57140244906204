module.exports = () => {
  return {
    name_en: 'Readyplanet Public Co.,Ltd.',
    name_th: 'บริษัท เรดดี้แพลนเน็ต จำกัด',
    address_1: 'เลขที่ 51 อาคารเมเจอร์ ทาวเวอร์ พระราม 9 - รามคำแหง ชั้น 17 ห้อง 1701 - 1706',
    address_2: 'ถนนพระราม 9 แขวงหัวหมาก เขตบางกะปิ กรุงเทพมหานคร 10240',
    company_email: 'info@readyplanet.com',
    company_homepage: 'https://www.readyplanet.com',
    app_email: 'pointspot@readyplanet.com',
    tel: {
      display: '02-016-6789',
      number: '020166789'
    },
    fax: {
      display: '02-016-6901',
      number: '020166901'
    },
    jobs: 'https://jobs.readyplanet.com/',
    jobs_code: 'POINTSPOT2019'
  }
}