import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Navbar } from "reactstrap";

import { Title1 } from "../Font";
import DropdownAccount from "../DropDownAccount";
// import DropdownLanguage from "./DropdownLanguage";
import {
  HambergerIcon,
  NavbarContainer,
  NavbarTitle,
  ProfileNavContainer,
  TopBar,
} from "./Navbar.style";
import "./Navbar.css";
import { connect } from "react-redux";
import { hexToHSL } from "../../libs/hextohsl";

class NavbarSection extends Component {
  gotoPageNav() {
    if (this.props.location.pathname.indexOf("/collect") > -1) {
      this.props.history.push(this.props.goTo);
    } else if (this.props.location.pathname.indexOf("/editprofile") > -1) {
      this.props.history.push("/profile");
    } else if (this.props.location.pathname.indexOf("/notification") > -1) {
      this.props.history.push("/profile");
    } else {
      if (this.props.togglePage) this.props.togglePage();
      else this.props.history.goBack();
    }
  }

  render = () => {
    let {
      useMenuToggle,
      title,
      toggle,
      hiddenProfile,
      // hiddenLang,
      isBusiness,
      blockNoti,
      themeColor,
    } = this.props;
    let {
      store_profile: { profile },
    } = this.props;
    let {
      store_language: { dictionary },
    } = this.props;

    let ProfileNav = false;
    let SettingNav = false;

    let ProfilePage = [
      "/editprofile",
      "/address",
      "/changephone",
      "/language",
      "/notification",
      "/line",
      "/settingbusiness",
    ];

    if (this.props.location.pathname === "/profile") {
      ProfileNav = true;
    } else if (ProfilePage.includes(this.props.location.pathname)) {
      SettingNav = true;
    }

    return (
      <>
        {!ProfileNav ? (
          <Navbar
            className={`p-0 ${!isBusiness && "position-sticky"}`}
            style={{
              height: 45,
              marginLeft: isBusiness ? "env(safe-area-inset-left)" : "",
              marginRight: isBusiness ? "env(safe-area-inset-right)" : "",
            }}
            fixed={`${isBusiness && "top"}`}
          >
            <NavbarContainer
              gradient={isBusiness}
              bgTheme={themeColor}
              className="d-flex align-items-center"
              isSetting={SettingNav}
            >
              {Boolean(useMenuToggle) ? (
                <NavbarTitle
                  className="d-inline-block p-2 ml-2 mr-2"
                  onClick={toggle}
                  isSetting={SettingNav}
                >
                  <i
                    className="fa fa-bars fa-xl"
                    aria-hidden="true"
                    style={{
                      color: themeColor ? hexToHSL(themeColor, { l: 98 }) : "",
                    }}
                  ></i>
                  {profile &&
                  profile[0] &&
                  profile[0].email_verified === false &&
                  profile[0].email ? (
                    <i
                      className="fa fa-circle fa-xl fa-xs"
                      aria-hidden="true"
                    />
                  ) : null}
                </NavbarTitle>
              ) : (
                <NavbarTitle
                  className="d-inline-block p-2 ml-2 mr-2 page-back"
                  onClick={() => {
                    this.gotoPageNav();
                  }}
                  isSetting={SettingNav}
                >
                  <i
                    className="fas fa-chevron-left fa-lg"
                    style={{
                      fontSize: 16,
                      color: themeColor ? themeColor : "",
                    }}
                    aria-hidden="true"
                  />
                </NavbarTitle>
              )}
              <div className="d-inline-block align-self-center overflow-hidden">
                <Title1 bold>
                  <NavbarTitle
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: themeColor ? themeColor : "",
                    }}
                    isSetting={SettingNav}
                  >
                    {dictionary[title] || title}
                  </NavbarTitle>
                </Title1>
              </div>
              <div className="lang-and-profile">
                {blockNoti && (
                  <Title1 bold>
                    <NavbarTitle>{blockNoti}</NavbarTitle>
                  </Title1>
                )}
                {hiddenProfile ? (
                  <div className="d-inline-block p-2 ml-2 mr-2">
                    {Boolean(useMenuToggle) ? (
                      <i
                        className="fas fa-chevron-left fa-lg"
                        style={{ fontSize: 16 }}
                        hidden
                      />
                    ) : (
                      <i className="fa fa-bars fa-xl" hidden />
                    )}
                  </div>
                ) : (
                  <TopBar>
                    <DropdownAccount />
                  </TopBar>
                )}
              </div>
            </NavbarContainer>
          </Navbar>
        ) : (
          <ProfileNavContainer>
            <HambergerIcon onClick={toggle}>
              <i className="fa fa-bars fa-xl" aria-hidden="true" />
              {profile &&
              profile[0] &&
              profile[0].email_verified === false &&
              profile[0].email ? (
                <i
                  className="fa fa-circle fa-xl fa-xs"
                  aria-hidden="true"
                  style={{ top: "18px" }}
                />
              ) : null}
            </HambergerIcon>
          </ProfileNavContainer>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    store_profile: state.profile,
    store_language: state.language,
  };
};

export default withRouter(connect(mapStateToProps)(NavbarSection));
