import styled, { css } from "styled-components";
import { hexToHSL } from "../../libs/hextohsl";

export const NavbarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  background-color: ${(props) => props.theme.secondary};
  // box-shadow: 0px 3px 6px 0px ${(props) => props.theme.shadow};
  ${(props) =>
    props.gradient
      ? css`
          background: linear-gradient(180deg, #000000a8, transparent);
        `
      : props.bgTheme
      ? css`
          background-color: ${hexToHSL(props.bgTheme, { l: 98 })};
        `
      : ""}
  ${(props) =>
    props.isSetting &&
    css`
      background-color: #516373;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    `}
`;

export const NavbarTitle = styled.div`
  color: ${(props) => props.theme.primary};

  ${(props) =>
    props.isSetting &&
    css`
      color: white;
    `}
`;

export const TopBar = styled.div`
  z-index: 1020;
  display: flex;
  position: absolute;
  right: 13px;
  align-items: center;
`;

export const ProfileNavContainer = styled.div`
  background-color: transparent;
  position: absolute;
`;

export const HambergerIcon = styled.div`
  display: inline-block;
  padding: 8px;
  margin-left: 8px;
  margin-right: 8px;
  color: white;
  cursor: pointer;
`;
