import React, { Component } from 'react'
import Logo from '../assets/img/m-logo-96.png'

export default Present =>
  class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
        message: ''
      }
      this.loaded = this.loaded.bind(this)
      this.reload = this.reload.bind(this)
      this.setErrorMessage = this.setErrorMessage.bind(this)
    }

    loaded() {
      this.setState({ loading: false })
    }

    reload() {
      this.setState({ loading: false })
    }

    setErrorMessage(newMessage) {
      this.setState({ loading: true, message: newMessage })
    }

    render() {
      let _style = { top: 0, left: 0, position: 'fixed', height: 'calc( 100% )', background: 'white', zIndex: '999' }

      return <div style={{ height: '100%' }}>
        {
          this.state.loading
            ? <div className='w-100 d-flex align-items-center justify-content-center' style={_style}>
              <div className='w-100 text-center' style={{ color: '#516373' }}>
                <p>
                  <img src={Logo} alt="Pointspot" className="img-fluid" />
                </p>
                {
                  this.state.message
                    ? <div className='alert-danger- p-3'>
                      <span style={{ color: '#556677' }}>{this.state.message}<br />
                        <a href="/">Reload...</a>
                      </span>
                    </div>
                    : <p>
                      <i className="fa fa-spinner fa-pulse fa-1x" />
                    </p>
                }
              </div>
            </div>
            : null
        }

        <Present {...this.props}
          loaded={this.loaded}
          reload={this.reload}
          loading={this.state.loading}
          setErrorMessage={this.setErrorMessage} />
      </div>
    }
  }